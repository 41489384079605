<div style="display: inline-block; width: 213px;">
    <div style="padding: 10px; background-color: #d3d3d3; text-align: center;">
        <h4 class="text-bold">Select Countires</h4>
    </div>
    <div class="container-fluid">
        <div class="row">
            <input type="text" class="form-control form-control-sm rounded-0 shadow-0" name="search" id="search"
                placeholder="Search Country" (input)="filterList($event)">
        </div>
        <div class="overflow-auto custom-scroll slimScrollBar" style="height: 300px; width: 200px">
            <div class="p-1 AllRows" *ngIf="loading">
                <app-ag-loading></app-ag-loading>
            </div>
            <div class="p-1 AllRows" *ngFor="let fieldOption of countries">
                <label class="filterdata d-flex" >
                    <input type="checkbox" class="set-checkbox mt-1" (change)="valueChanged($event)"
                        value="{{getValue(fieldOption)}}">&nbsp;
                    <app-country-flag class="overFlowDiv" [width]="30" [country]="fieldOption.name" (mouseenter) ="addClassOverFlow($event)" (mouseleave) ="removeClassOverFlow($event)"> </app-country-flag>
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col p-0">
                <button (click)="apply()" class="btn btn-sm btn-block rounded-0 btn-success pull-right">Apply</button>
            </div>
            <div class="col p-0">
                <button (click)="clear()" class="btn btn-sm btn-block rounded-0 btn-danger pull-right">Clear</button>
            </div>
        </div>
    </div>
</div>
