import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ShowEncryptService } from '../services/show-encrypt.service';
import { UserPreferencesService } from '@app/portal/user-preferences/user-preferences.service';

@Component({
    template: `<div>
                    <a href="javascript:;" (click)="showData(params)">{{value}}</a>
               </div>`
})
export class EncryptValueRendererComponent implements AgRendererComponent {
    public params: any;
    public bsModelRef: BsModalRef;
    public value: any;
    private readonly DEFAULT_VALUE = "****";

    constructor (
                protected bsModalSerivce: BsModalService,
                private showEncryptService: ShowEncryptService,
                private userPreferencesService: UserPreferencesService) {}

    agInit(params: any): void {
        this.params = params;
        this.showEncryptData(params.value);
    }

    refresh(params: any): boolean {
        return false;
    }

    showEncryptData(value: string) {
        this.value = value ? value : this.DEFAULT_VALUE;
    }

    async showData(params: any) {
        let isPhoneEnable=true;
        if(this.params.colDef.field == 'primary_phone')
        {

            isPhoneEnable=await this.userPreferencesService.checkPhonePreferenceEnable();
        }
        if(this.value == this.DEFAULT_VALUE && isPhoneEnable) {
            const payloadData = {
                module_id: this.params.module_id,
                field: this.params.colDef.field,
                data: this.params.data
            };
            const response = await this.showEncryptService.showEncryptDataAsync(payloadData);
            this.value = response;
        }
    }
}
