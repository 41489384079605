import { Component, OnInit } from '@angular/core';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { UserPreferencesService } from '@app/portal/user-preferences/user-preferences.service';
import { TraderoomResponse } from './model/traderoom.model';
import { TraderoomService } from './traderoom.service';
@Component({
    selector: 'app-leader-board-traderoom',
    templateUrl: './leader-board-traderoom.component.html',
    styleUrls: ['./leader-board-traderoom.component.css']
})
export class LeaderBoardTraderoomComponent extends AbstractBaseComponent implements OnInit {

    public leaderBoardList: TraderoomResponse[] = [];
    public todayTotal: number = 0;
    public monthlyTotal: number = 0;
    public total: number = 0;
    public preferedCurrency = this.userPreferencesService.getPreferedCurrency();
    public targets: any = {
        data: [
            {},
            {},
            {}
        ]
    };
    public calcPercentages: any = {};
    private durations: any = {
        TODAY: 0,
        MONTH: 1,
        TOTAL: 2,
    };

    constructor(
        private traderRoomService: TraderoomService,
        private userPreferencesService: UserPreferencesService,
        private appDataService: ApplicationDataService,
        private profileService: ProfileService
    ) {
        super();
    }

    async ngOnInit() {
        setInterval(this.time, 1000);
        let loggedUser = await this.getUser();
        await this.fetchTraderoomAsync();
        if (loggedUser.id == 1) {
            this.targets.data[this.durations.TODAY].target = 1000000;
            this.targets.data[this.durations.MONTH].target = 10000000;
            this.targets.data[this.durations.TOTAL].target = 100000000;
        } else {
            let brands = await this.appDataService.getBrands();
            this.fetchBrandTargetsAsync(brands[0].id);
        }
        this.calcPercentages = this.getPercentages;
    }

    private async getUser() {
        this.loadingStart();
        let user = await this.profileService.getUser();
        this.loadingFinished();
        return user;
    }

    public time() {
        let date = new Date();
        let dateTime = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " + date.toLocaleTimeString();
        return dateTime;
    }

    private async fetchBrandTargetsAsync(brandId: number) {
        try {
            const response: { targets: string } = await this.traderRoomService.fetchBrandTargetsAsync(brandId);
            this.targets = JSON.parse(response.targets);
        } catch (error) {

        }
    }

    private async fetchTraderoomAsync() {
        try {
            this.loadingStart();
            let response = await this.traderRoomService.fetchTraderoomAsync();
            this.leaderBoardList = response;
            this.leaderBoardList.forEach((res) => {
                this.todayTotal += res.total_deposit_amount_today
                this.monthlyTotal += res.total_deposit_amount_this_month
                this.total += res.total_deposit_amount
            });
            this.loadingFinished();
        } catch (error) {
            this.loadingFinished();

        }
    }

    public get dailyMonster() {
        return this.leaderBoardList[0];
    }

    public get getPercentages() {
        let today = Math.round((+this.todayTotal * 100) / this.targets.data[this.durations.TODAY]?.target || 0);
        let month = Math.round((+this.monthlyTotal * 100) / this.targets.data[this.durations.MONTH]?.target || 0);
        let total = Math.round((+this.total * 100) / this.targets.data[this.durations.TOTAL]?.target || 0);

        return ({
            today: today,
            month: month,
            total: total
        });
    }

}
