import { Component, Input, OnInit } from '@angular/core';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { IFloatingFilter } from 'ag-grid-community';

@Component({
  selector: 'app-ag-loading',
  templateUrl: './ag-loading.component.html',
  styleUrls: ['./ag-loading.component.css']
})
export class AgLoadingComponent implements IFloatingFilter, AgFrameworkComponent<any> {
  @Input() height = '200px';
  params: any;
  constructor() { }

  async agInit(params: any) {
  }
  ngOnInit() {
  }
  onParentModelChanged(model: any) {
  }
  getModel() {
  }

  isFilterActive() {
    return 0;
  }
  async getValues() {
    return 0;
  }
  get style() {
    return { height: this.height };
  }

}
