import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { AccountFilter, AccountResponse, AccountsResponse, AssignAccountsPayload, Import, ImportResponse, Ownership, OwnershipResponse } from './models/account.model';
import { Account, AccountsCountriesResponse, ListedAccountsDocumentStatusResponse, ListedAccountsStatusResponse } from '@app/shared/models/account.model';
import { AccountOverviewResponse } from './models/account-overview.model';
import { AccountAlert, NotificationFilter } from './models/client-notifications.model';


@Injectable({ providedIn: 'root' })
export class AccountsService extends BaseAPIClass {

    private readonly URL_FETCH_ACCOUNTS: string = 'accounts/list';
    private readonly URL_FETCH_ACCOUNTS_GRID: string = 'accounts/list-grid';
    private readonly URL_FETCH_ACCOUNTS_OVERVIEW: string = 'accounts/client_trading_accounts_overview';
    private readonly URL_FETCH_SINGLE_ACCOUNT: string = 'accounts/single_account/{id}';
    private readonly URL_ACCOUNT_REJISTER: string = 'accounts/register';
    private readonly URL_UPDATE_ACCOUNTS: string = 'accounts/update';
    private readonly URL_DELETE_ACCOUNTS: string = 'accounts/delete/{id}';
    private readonly URL_CHANGE_PASSWORD: string = 'accounts/change_password/{id}';
    private readonly URL_ACCOUNT_MASS_DELETE: string = 'accounts/mass_delete';
    private readonly URL_ACCOUNT_MASS_UPDATE: string = 'accounts/mass_update';
    private readonly URL_ACCOUNT_DISABLE: string = 'accounts/disable/{id}';
    private readonly URL_ACCOUNT_ENABLE: string = 'accounts/enable/{id}';
    private readonly URL_ACCOUNT_TRANSFER_OWNERSHIP: string = 'accounts/transfer_ownership';
    private readonly URL_ACCOUNT_IMPORT: string = 'accounts/import';
    private readonly URL_ACCOUNT_EXPORT: string = 'accounts/export';
    private readonly URL_CREATE_FAVORITE_ACCOUNTS: string = 'accounts/add-to-favorites';
    private readonly URL_REMOVE_FAVORITE_ACCOUNTS: string = 'accounts/remove-from-favorites';
    private readonly URL_FETCH_FAVORITE_ACCOUNTS: string = 'accounts/list-favorites';
    private readonly URL_MASS_STATUS_UPDATE: string = 'accounts/mass_status_update';

    private readonly URL_FETCH_CLIENT_NOTIFICATIONS: string = 'client-activity-notifications/my-notifications/{id}';
    private readonly URL_CLIENT_NOTIFICATIONS: string = 'client-activity-notifications';

    private readonly URL_UPDATE_CLIENT_TRADING_ACCOUNT_STATUS: string = 'trading_accounts/status';
    private readonly URL_RESEND_CREDENTIALS: string = 'accounts/resend_credentials';
    private readonly URL_GET_LISTED_COUNTRIES: string = 'accounts/get_listed_countries';
    private readonly URL_GET_LISTED_STATUS: string = 'accounts/get_listed_status';
    private readonly URL_GET_LISTED_DOCUMENT_STATUS: string = 'accounts/get_listed_document_status';
    private readonly URL_MAKE_CALL: string = 'accounts/call';



    constructor(
        protected httpClient: HttpClient,
        protected authenticationService: AuthenticationService) {
        super(httpClient, authenticationService);
    }

    public async fetchAccountsAsync(filters: AccountFilter) {
        return await this.getAsync<AccountsResponse>(this.URL_FETCH_ACCOUNTS, filters, {}, false).toPromise();
    }

    public async fetchAccountsForGridAsync(filters: AccountFilter) {
        return await this.postAsync<AccountsResponse>(this.URL_FETCH_ACCOUNTS_GRID, filters, {}, false).toPromise();
    }

    public async fetchTradingAccountsOverviewForClient(client_id: number) {
        return await this.getAsync<AccountOverviewResponse>(this.URL_FETCH_ACCOUNTS_OVERVIEW + '/' + client_id, {}, {}, false).toPromise();
    }

    public async fetchAccountByIdAsync(id: number, filterModel: any = null) {
        const url = this.URL_FETCH_SINGLE_ACCOUNT.replace('{id}', id.toString());
        return await this.postAsync<AccountResponse>(url, {filterModel}, null).toPromise();
    }

    public async saveAccountAsync(account: Account) {
        return await this.postAsync<AccountResponse>(this.URL_ACCOUNT_REJISTER, account, {}, false).toPromise();
    }

    public async updateAccountAsync(account: Account) {
        return await this.postAsync<AccountResponse>(this.URL_UPDATE_ACCOUNTS, account, {}, false).toPromise();
    }

    public async updateClientTradingAccountStatus(accountId: number, statusId: number) {
        return await this.getAsync<AccountResponse>(this.URL_UPDATE_CLIENT_TRADING_ACCOUNT_STATUS +'/'+accountId+'/'+statusId, {}, {}, false).toPromise();
    }

    public async deleteAccountAsync(account: Account) {
        const url = this.URL_DELETE_ACCOUNTS.replace('{id}', account.id.toString());
        console.log(url);
        return await this.deleteAsync<AccountResponse>(url, account, {}, false).toPromise();
    }

    public async changePasswordAsync(account: Account) {
        const url = this.URL_CHANGE_PASSWORD.replace('{id}', account.id.toString());
        return await this.postAsync<AccountResponse>(url, account, {}, false).toPromise();
    }

    public async massDisableAccountsAsync(massIds: number[]) {
        return await this.postAsync<AccountResponse>(this.URL_ACCOUNT_MASS_DELETE, { accounts_id: massIds }, {}, false).toPromise();
    }

    public async massUpdateAccountsAsync(account: Account) {
        return await this.postAsync<AccountResponse>(this.URL_ACCOUNT_MASS_UPDATE, account, {}).toPromise();
    }

    public async diableAccountAsync(accountId: number) {
        const url = this.URL_ACCOUNT_DISABLE.replace('{id}', accountId.toString());
        return await this.postAsync<AccountResponse>(url, { accounts_id: accountId }, {}, false).toPromise();
    }

    public async enableAccountAsync(accountId: number) {
        const url = this.URL_ACCOUNT_ENABLE.replace('{id}', accountId.toString());
        return await this.postAsync<AccountResponse>(url, { accounts_id: accountId }, {}, false).toPromise();
    }

    public async OwnershipAsync(ownership: AssignAccountsPayload) {
        return await this.postAsync<OwnershipResponse>(this.URL_ACCOUNT_TRANSFER_OWNERSHIP, ownership, {}, false).toPromise();
    }

    public async accountImportAsync(accountImport: Import) {
        return await this.postAsync<ImportResponse>(this.URL_ACCOUNT_IMPORT, accountImport, {}, false).toPromise();
    }

    public async accountExportAsync(account: Account) {
        return await this.postAsync<AccountResponse>(this.URL_ACCOUNT_EXPORT, account, {}, false).toPromise();
    }

    public async fetchClientNotificationsAsync(data: number) {
        const URL = this.URL_FETCH_CLIENT_NOTIFICATIONS.replace('{id}', data.toString());
        return await this.getAsync<any>(URL, {}, {}, false).toPromise();
    }

    public async setClientActivityNotif(data: AccountAlert) {
        return await this.postAsync<any>(this.URL_CLIENT_NOTIFICATIONS, data, {}, false).toPromise();
    }
    public async getClientsActivityNotif(filter: NotificationFilter) {
        return await this.getAsync<any>(this.URL_CLIENT_NOTIFICATIONS, filter, {}, false).toPromise();
    }
    public async saveFavoriteLeadsAsync(accountIds: {}) {
        return await this.postAsync<any>(this.URL_CREATE_FAVORITE_ACCOUNTS, accountIds, {}, false).toPromise();
    }
    public async removeFavoriteLeadsAsync(accountIds: {}) {
        return await this.postAsync<any>(this.URL_REMOVE_FAVORITE_ACCOUNTS, accountIds, {}, false).toPromise();
    }
    public async fetchFavoriteAccountsAsync() {
        return await this.postAsync<any>(this.URL_FETCH_FAVORITE_ACCOUNTS, {}, {}, false).toPromise();
    }

    public async resendCredentialsAsync(account : {}) {
        return await this.postAsync<any>(this.URL_RESEND_CREDENTIALS, account, {}, false).toPromise();
    }

    public async massStatusUpdateAsync(payload : {}) {
        return await this.postAsync<any>(this.URL_MASS_STATUS_UPDATE, payload, {}, false).toPromise();
    }

    public async getListedCountriesAsnc() {
        return await this.getAsync<AccountsCountriesResponse>(this.URL_GET_LISTED_COUNTRIES, {}, {}, false).toPromise();
    }

    public async getListedStatusAsnc() {
        return await this.getAsync<ListedAccountsStatusResponse>(this.URL_GET_LISTED_STATUS, {}, {}, false).toPromise();
    }

    public async getListedDocumentStatusAsnc() {
        return await this.getAsync<ListedAccountsDocumentStatusResponse>(this.URL_GET_LISTED_DOCUMENT_STATUS, {}, {}, false).toPromise();
    }


    public async makeCall(payload: any) {
        return await this.postAsync(this.URL_MAKE_CALL, payload, {}, false).toPromise();
    }
}
