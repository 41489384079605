import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-settings-modal',
  templateUrl: './app-settings-modal.component.html',
  styleUrls: ['./app-settings-modal.component.css']
})
export class AppSettingsModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
