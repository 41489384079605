import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutes } from '@app/app.routing';
import { AppComponent } from '@app/app.component';
import { AuthenticationGuard, CoreModule, LoggedInGuard } from '@app/core';
import { SharedModule } from '@app/shared';
import { SidebarComponent } from './portal/sidebar/sidebar.component';
import { ProfileService } from './portal/settings/profile/profile.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ApplicationDataService } from './core/service/application-data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { LeaderBoardTraderoomComponent } from './leader-board-traderoom/leader-board-traderoom.component';

@NgModule({
  declarations: [AppComponent, UnderMaintenanceComponent,LeaderBoardTraderoomComponent],
  exports: [SidebarComponent],
  imports: [
    CoreModule,
    AuthenticationModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    RouterModule.forRoot(AppRoutes),
    SharedModule,
    FullCalendarModule,
    FormlyModule.forRoot(),
    FormlyBootstrapModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent],
  providers: [
    ApplicationDataService,
    ProfileService,
    LoggedInGuard,
    AuthenticationGuard],
})
export class AppModule {}
