import { GenericEvent } from '@app/shared/models/generic-event.model';
import { FieldOption } from './../../settings/field-options/model/field-option.model';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { ToastrService } from '@app/shared/services/toastr.service';
import { LocalStorageService } from '@app/core/local-storage.service';
import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { User } from '@app/shared/models/user.model';
import { UserPreferencesService } from '../user-preferences.service';
import { UserPref } from '../user-pref-model/user-pref.model';
declare const $: any;
@Component({
  selector: 'app-manage-preferences',
  templateUrl: './manage-preferences.component.html',
  styleUrls: ['./manage-preferences.component.css']
})
export class ManagePreferencesComponent extends AbstractBaseComponent implements OnInit, OnDestroy {
  public payload: UserPref | any = {
    deposit_by_day: true,
    latest_activities: true,
    deposit_by_assignee: true,
    monthly_deposit_withdraw: true,
    daily_deposit_withdraw: true,
    lead_by_status: true,
    ticket_by_status: true,
    favourite_leads: true,
    favourite_Accounts: true,
    clients_by_status: true,
    deposit_by_countries: true,
    preferred_currency: 'USD',
  };
  @Output() public emitData = new EventEmitter<any>();
  public storage: any = {};
  public userSetting: any = {};
  public myProfile: User;
  public dashboard_id: string;
  public count = 0;
  public curriences: FieldOption[] = [];
  public user_Preferences: any = [
    {
      labelName: 'Latest Activities',
      labelId: 'latest_activitieslabel',
      id: 'latest_activities',
    },
    {
      labelName: 'Deposit By Assignees',
      labelId: 'deposit_by_assigneelabel',
      id: 'deposit_by_assignee',
    },
    {
      labelName: 'Deposit By Day',
      labelId: 'deposit_by_daylabel',
      id: 'deposit_by_day',
    },
    {
      labelName: 'Monthly Deposit And Withdrawl',
      labelId: 'monthly_deposit_withdrawlabel',
      id: 'monthly_deposit_withdraw',
    },
    {
      labelName: 'Daily Deposit And Withdrawl',
      labelId: 'daily_deposit_withdrawlabel',
      id: 'daily_deposit_withdraw',
    },
    {
      labelName: 'Clients By Status',
      labelId: 'clients_by_statuslabel',
      id: 'clients_by_status',
    },
    {
      labelName: 'Lead By Status',
      labelId: 'lead_by_statuslabel',
      id: 'lead_by_status',
    },
    {
      labelName: 'Ticket By Status',
      labelId: 'ticket_by_statuslabel',
      id: 'ticket_by_status',
    },
    {
      labelName: 'Deposit By Countries',
      labelId: 'deposit_by_countrieslabel',
      id: 'deposit_by_countries',
    },
  ];
  constructor(private localStorageService: LocalStorageService,
    private profileservice: ProfileService,
    private appEvent: ApplicationEvent,
    private appliactionDataService: ApplicationDataService,
    private userPreferencesService: UserPreferencesService,
    private toastrService: ToastrService) {
    super();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  async ngOnInit() {
    this.subscribe(this.appEvent.onGenericEvent.subscribe(this.eventHandler.bind(this)));
    this.myProfile = await this.profileservice.getUser();
    const fieldOption = await this.appliactionDataService.getFieldOptions();
    this.curriences = fieldOption[20].fieldOptions;
    this.dashboard_id = 'dashboard-' + this.myProfile.id;
    this.userSetting = JSON.parse(this.localStorageService.getItem(this.dashboard_id));
    if (this.userSetting) {
      setTimeout(() => {
        this.loadUserSetting();
      }, 2000);
    }
  }
  public eventHandler(event: GenericEvent) {
    if (event.name === 'prefered-currency-set') {
      this.payload['preferred_currency'] = event.payload.name;
      this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
      this.userSetting.preferred_currency = event.payload.name;
      const checkboxes = document.getElementsByName('currency');
      checkboxes.forEach((item: any) => {
        if (item.id !== event.payload.id) {
          const labelId = item.id + 'label';
          $('#' + labelId).css('color', 'black');
          item.checked = false;
        }
      });
      this.checkCurrency();
      this.saveUserPrefCurrency();
    }
    if (event.name === 'set-user-preference') {
      if (this.payload.hasOwnProperty(event.payload)) {
        this.payload[event.payload] = false;
        this.loadUserPref();
      }
    }
  }

  public loadUserPref() {
    let prefLength = 0;
    for (const obj in this.payload) {
      if (this.payload.hasOwnProperty(obj)) {
        if (obj !== 'preferred_currency') {
          $('#' + obj).prop('checked', this.payload[obj]);
          $('#' + obj + 'label').css('color', this.payload[obj] ? 'blue' : 'black');
          // this.payload[obj] = this.payload[obj];
          this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
          if (this.payload[obj]) {
            prefLength++;
          }
        }
      }
    }
    if (prefLength === this.user_Preferences.length) {
      $('#selectAllLabel').css('color', 'blue');
      $('#selectAll').prop('checked', true);
    } else {
      $('#selectAllLabel').css('color', 'black');
      $('#selectAll').prop('checked', false);
    }
    this.savePreference();
  }

  public loadUserSetting() {
    for (const obj in this.userSetting) {
      if (this.userSetting.hasOwnProperty(obj)) {
        if (obj !== 'preferred_currency') {
          $('#' + obj).prop('checked', this.userSetting[obj]);
          $('#' + obj + 'label').css('color', this.userSetting[obj] ? 'blue' : 'black');
          this.payload[obj] = this.userSetting[obj];
          this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
          if (this.payload[obj]) {
            this.count++;
          }
        }
      }
    }
    if (this.count === this.user_Preferences.length) {
      $('#selectAllLabel').css('color', 'blue');
      $('#selectAll').prop('checked', true);
    }
    this.checkCurrency();
  }

  public checkCurrency() {
    if (this.userSetting.preferred_currency === 'USD') {
      $('#81').prop('checked', true);
      $('#81label').css('color', 'blue');
      this.payload['preferred_currency'] = this.userSetting.preferred_currency;
      this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
    } else if (this.userSetting.preferred_currency === 'PKR') {
      $('#84').prop('checked', true);
      $('#84label').css('color', 'blue');
      this.payload['preferred_currency'] = this.userSetting.preferred_currency;
      this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
    } else if (this.userSetting.preferred_currency === 'EUR') {
      $('#82').prop('checked', true);
      $('#82label').css('color', 'blue');
      this.payload['preferred_currency'] = this.userSetting.preferred_currency;
      this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
    } else if (this.userSetting.preferred_currency === 'GBP') {
      $('#83').prop('checked', true);
      $('#83label').css('color', 'blue');
      this.payload['preferred_currency'] = this.userSetting.preferred_currency;
      this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
    }
  }
  public currencyChange(event: any) {
    const id = event.target.id;
    if (id === '81' || id === '82' || id === '83' || id === '84') {
      const checkboxes = document.getElementsByName('currency');
      checkboxes.forEach((item: any) => {
        if (item.id !== event.target.id && event.target.checked) {
          const labelId = item.id + 'label';
          $('#' + labelId).css('color', 'black');
          item.checked = false;
        } else if (item.id === event.target.id) {
          item.checked = true;
          switch (id) {
            case '81':
              $('#81label').css('color', 'blue');
              this.payload['preferred_currency'] = 'USD';
              this.appEvent.fireGenericEvent({ name: 'currency-set', payload: this.curriences[0] });
              this.appEvent.fireGenericEvent({ name: 'set-currency-name', payload: this.curriences[0] });
              this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
              this.localStorageService.setItem('currency', 'USD');
              break;
            case '84':
              $('#84label').css('color', 'blue');
              this.payload['preferred_currency'] = 'PKR';
              this.appEvent.fireGenericEvent({ name: 'currency-set', payload: this.curriences[3] });
              this.appEvent.fireGenericEvent({ name: 'set-currency-name', payload: this.curriences[3] });
              this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
              this.localStorageService.setItem('currency', 'PKR');
              break;
            case '82':
              $('#82label').css('color', 'blue');
              this.payload['preferred_currency'] = 'EUR';
              this.appEvent.fireGenericEvent({ name: 'currency-set', payload: this.curriences[1] });
              this.appEvent.fireGenericEvent({ name: 'set-currency-name', payload: this.curriences[1] });
              this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
              this.localStorageService.setItem('currency', 'EUR');
              break;
            case '83':
              $('#83label').css('color', 'blue');
              this.payload['preferred_currency'] = 'GBP';
              this.appEvent.fireGenericEvent({ name: 'currency-set', payload: this.curriences[2] });
              this.appEvent.fireGenericEvent({ name: 'set-currency-name', payload: this.curriences[2] });
              this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
              this.localStorageService.setItem('currency', 'GBP');
              break;
          }
        }
      });
    }
  }
  public change(event: any) {
    const id = event.target.id;
    if (event.target.checked) {
      $('#' + id + 'label').css('color', 'blue');
      this.payload[id] = true;
      this.count++;
      this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
    } else {
      $('#' + id + 'label').css('color', 'black');
      this.payload[id] = false;
      this.count--;
      this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
    }
    if (this.count === this.user_Preferences.length) {
      $('#selectAllLabel').css('color', 'blue');
      $('#selectAll').prop('checked', true);
    } else {
      $('#selectAllLabel').css('color', 'black');
      $('#selectAll').prop('checked', false);
    }
  }
  public changeAll(event: any) {
    if (event.target.checked) {
      const id = event.target.id;
      $('#selectAllLabel').css('color', 'blue');
      const checkboxes = document.getElementsByName('widget');
      checkboxes.forEach((item: any) => {
        const labelId = item.id + 'label';
        $('#' + labelId).css('color', 'blue');
        item.checked = true;
      });
      this.payload = {
        deposit_by_day: true,
        latest_activities: true,
        deposit_by_assignee: true,
        monthly_deposit_withdraw: true,
        daily_deposit_withdraw: true,
        lead_by_status: true,
        ticket_by_status: true,
        clients_by_status: true,
        deposit_by_countries: true,
        preferred_currency: this.userSetting.preferred_currency,
      };
      this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
    } else {
      $('#selectAllLabel').css('color', 'black');
      const checkboxes = document.getElementsByName('widget');
      checkboxes.forEach((item: any) => {
        const labelId = item.id + 'label';
        $('#' + labelId).css('color', 'black');
        item.checked = false;
      });
      this.payload = {
        deposit_by_day: false,
        latest_activities: false,
        deposit_by_assignee: false,
        monthly_deposit_withdraw: false,
        daily_deposit_withdraw: false,
        lead_by_status: false,
        ticket_by_status: false,
        deposit_by_countries: false,
        clients_by_status: false,
        preferred_currency: this.userSetting.preferred_currency,
      };
      this.localStorageService.setItem(this.dashboard_id, JSON.stringify(this.payload));
    }
  }


  public async saveUserPrefCurrency() {
    const payload2: any = {};
    payload2['data'] = JSON.stringify(this.payload);
    try {
      //const response = await this.userPreferencesService.updatePreference(payload2);
    } catch (error) {
    }
  }
  public async savePreference() {
    const payload2: any = {};
    payload2['data'] = JSON.stringify(this.payload);
    try {
      //await this.userPreferencesService.updatePreference(payload2);
    } catch (error) {
    }
  }
  public async saveUserPref(event: any) {
    this.httpProcessStart();
    $(event.target).attr('disabled', true);
    const payload2: any = {};
    payload2['data'] = JSON.stringify(this.payload);
    try {
      //const response = await this.userPreferencesService.updatePreference(payload2);
      //this.toastrService.toastSuccess(response.message);
      this.httpProcessEnd();
      $(event.target).attr('disabled', false);
      this.appEvent.fireGenericEvent({ name: 'user-preference-saved' });
      this.emitData.emit({ name: 'close-user-pref' });
    } catch (error) {
      this.httpProcessEnd();
      $(event.target).attr('disabled', false);
    }
  }
}
