import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { ToastrService } from '@app/shared/services/toastr.service';
import { ChangeTradingAccountPassswordResponse } from '../models/trading-platform-account.model';
import { TradingAccountService } from '../trading-account.service';

@Component({
  selector: 'app-change-trading-account-password',
  templateUrl: './change-trading-account-password.component.html',
  styleUrls: ['./change-trading-account-password.component.css']
})
export class ChangeTradingAccountPasswordComponent implements OnInit {
  @Output() emitData = new EventEmitter();
  public restForm: FormGroup;
  public ready = false;
  public httpProcess = false;
  public Type: string = 'password';

  constructor(
    private formBuilder: FormBuilder,
    private tradingAccountService: TradingAccountService,
    private toastrService: ToastrService,
    private applicationEvent: ApplicationEvent
  ) {}

  ngOnInit() {
    this.intiForm();
  }

  public setDetail(data: any) {
    this.restForm.controls['trading_account_id'].setValue(data.data.trading_account_id);
  }

  private intiForm() {
    this.restForm = this.formBuilder.group({
      trading_account_id: [, [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]
    });
    this.ready = true;
  }


  public async changePassword() {
    try {
      this.httpProcess = true;
      const response: ChangeTradingAccountPassswordResponse =  await this.tradingAccountService.ChangeTradingAccountPasssword(this.restForm.value);
      this.toastrService.toastSuccess(response.message);
      this.applicationEvent.fireGenericEvent({name: 'password-reset'});
      console.log('event called adoen');

      this.close();
      this.httpProcess = false;
    } catch (error) {
      this.toastrService.toastError(error);
      this.httpProcess = false;
    }
  }

  public close() {
    this.emitData.emit({ name: 'close' });
  }

  public generatePassword(length: number) {
    let result = '';
    const uppercaseCharactor = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    result += uppercaseCharactor.charAt(Math.floor(Math.random() * uppercaseCharactor.length));
    const specailCharactors = '!@#$%&*';
    result += specailCharactors.charAt(Math.floor(Math.random() * specailCharactors.length));
    const numbers = '0123456789';
    result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    const characters = 'abcdefghijklmnopqrstuvwxyz' + uppercaseCharactor + specailCharactors + numbers;
    for (let i = 0; i < length-3; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    this.restForm.controls['password'].setValue(result);
  }

  public action() {
    if (this.Type === 'password') {
        this.Type = 'text';
    } else {
        this.Type = 'password';
    }
  }
}
