<div class="container h-100">
    <div class="row align-items-center vh-100">
        <div class="col-12">
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <div class="intro-image"></div>
                </div>
                <div  class="col-xs-12 col-md-6">
                    <div class="blankpage-form-field">
                        <div class="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                            <div *ngIf="hasErrors" class="alert bg-danger-400 text-white" role="alert">
                                <strong>Oh snap!</strong> Invalid account information.
                            </div>
                            <form id="js-login" [formGroup]="resetPasswordForm" (ngSubmit)="requestPasswordResetEmail()">
                                <div class="row">
                                    <div class="col">
                                        <h4 class="text-center mb-3">Forgot Password?</h4>
                                        <p class="text-muted mb-3">Enter the email address associated with your account and we will send you a link to reset your password.</p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-group">
                                        <label class="form-label" for="email">Email</label>
                                        <app-form-error-wrapper [control]="resetPasswordForm.controls['email']" [controlName]="'Email'">
                                          <input class="form-control" type="email" formControlName="email" placeholder="email@example.com" />
                                        </app-form-error-wrapper>
                                        <div class="help-block">Your password</div>
                                    </div>
                                    <div class="invalid-feedback">No, you missed this one.</div>
                                    <div class="help-block">We will email you the instructions</div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <button type="submit"  [disabled]="loading" class="shadow-0 btn btn-success btn-block btn-lg" [disabled]="!resetPasswordForm.valid"
                                        ><i class="fal fa-spinner fa-spin" *ngIf="httpProcess"></i> Recover Password</button>
                                        <a [routerLink]="'/login'" class="btn btn-outline-default btn-lg btn-block">Login</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="alert-modal-md" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 9999">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{alert?.title}}
                    <small class="m-0 text-muted">
                        {{alert?.sub_title}}
                    </small>
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body" [innerHTML]="alert?.body"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary shadow-0" data-dismiss="modal" (click)="closeModal()">{{alert?.close_text}}</button>
            </div>
        </div>
    </div>
  </div>
