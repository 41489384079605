<div [ngStyle]="{'height': height+'px'}" class="my-3">
    <div class="mt-custom">
        <img *ngIf="image" src="\assets\images\no-data.svg" class="main-no-record-wrapper" height="130" alt="">
    </div>
    <div class="no-records-wrapper">
        <h4 class="text-muted mt-4">{{text}}</h4>
        <div class="mt-2 ml-3">
            <ng-content></ng-content>
        </div>
    </div>
</div>
