<nav id="js-primary-nav" class="primary-nav" role="navigation">
    <ul id="js-nav-menu" class="nav-menu responsive-sidebar scroll">
        <li *ngFor="let navbarItem of NavbarItems" [ngClass]="{'nav-title': navbarItem.type === navType.NavTitle}"
            class="{{(navbarItem.link === currentLink) ? 'active' : ''}}" [attr.data-module-id]="navbarItem.id">
            <a href="javascript:;" (click)="gotoPage(navbarItem)" *ngIf="navbarItem.type === navType.Menu">
                <i class="{{ navbarItem.icon }}"></i>
                <span class="nav-link-text">{{ navbarItem.title }}</span>
                <span class="badge badge-warning periority">{{navbarItem.periority}}</span>
            </a>
            <ul *ngIf="navbarItem.type === navType.Menu && navbarItem.subMenu" [attr.id]="'level-1-'+navbarItem.id">
                <li *ngFor="let subNavbarItem of navbarItem.subMenu"
                    class="{{(subNavbarItem.link == currentLink) ? 'active' : ''}}">
                    <a href="javascript:;" (click)="gotoPage(subNavbarItem)">
                        <span class="nav-link-text">{{ subNavbarItem.title }}</span>
                    </a>
                    <ul *ngIf="subNavbarItem.subMenu"  [attr.data-module-id]="'level-2-'+navbarItem.id">
                        <li *ngFor="let subNavbarItem1 of subNavbarItem.subMenu"
                            [ngClass]="{'nav-title': navbarItem.type === navType.NavTitle}"
                            class="{{(subNavbarItem1.link == currentLink) ? 'active' : ''}}"
                            [attr.data-module-id]="subNavbarItem1.id">
                            <a href="javascript:;" (click)="gotoPage(subNavbarItem1)">
                                <span class="nav-link-text">{{ subNavbarItem1.title }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <span *ngIf="navbarItem.type === navType.NavTitle">{{ navbarItem.title }}</span>
        </li>
    </ul>
    <div class="filter-message js-filter-message bg-success-600"></div>
</nav>
