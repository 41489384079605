 <div class="d-flex">
                    <div class="mr-3">
                        <a  href="javascript:;View Account" (click)="showAccountQuickView(params?.data?.id)" class="btn btn-sm btn-default btn-icon rounded-circle position-relative js-waves-off shadow-0" style="width: 20px;
                            height: 20px;
                            background: radial-gradient(#28a745, #0f5fa0);
                            border-radius: 50%;
                            font-family: Arial, Helvetica, sans-serif;
                            color: white;">
                                <p style="height: 20px; line-height: 20px; padding: 0; margin: 0; font-size: 8px">{{genrateProfile(this.params.data?.first_name + ' ' + this.params.data?.last_name)}}</p>

                        </a>
                    </div>
                    <div><a [routerLink]="[ this.link ]" style="color: #333">{{this.params.data?.first_name}}</a></div>
                </div>