import { Component, Input, OnInit } from '@angular/core';
import { isDevMode } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-portal-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class PortalFooterComponent implements OnInit {
    public frontendVersion: string;
    @Input() public version: string;
    constructor() {}

    ngOnInit() {
        this.frontendVersion = environment.env.toLowerCase() + "-" + this.version;
    }
}
