import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordRequest } from './reset-password.model';
import { AuthenticationService } from '@app/core';
import { Alert } from '@app/shared/models/alert.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent extends AbstractBaseComponent implements OnInit {

    resetPasswordForm: FormGroup;
    private token: string;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private profileService: ProfileService,
        private route: ActivatedRoute) {
      super();

    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.token = params.token;
            if (this.token === '') {
                this.router.navigateByUrl('login?reason=invalid_reset_password_token');
            }
            this.createForm();
        });
    }

    private createForm() {
        this.resetPasswordForm = this.formBuilder.group({
            token: [this.token, [Validators.required]],
            password: ['', [Validators.required]],
            password_confirmation: ['', [Validators.required]]
        });
    }

    public async resetPassword() {
        try {
            const resetPasswordRequest: ResetPasswordRequest = this.resetPasswordForm.value;
            resetPasswordRequest.token = this.token;
            const resp = await this.profileService.resetPassword(resetPasswordRequest);
            this.router.navigateByUrl('login?reason=password_reset_successfuly');

            const alert: Alert = {title: 'Password Recovery',
            sub_title: 'Request completed',
            body: 'You password has been updated successfuly.',
            ok_text: 'OK',
            close_text: 'CLOSE'};
            this.handleModalAlert(alert);
        } catch (error) {
            console.log(error);
            this.hasErrors = true;
            this.httpError = error;
        }
    }

    public handleModalAlert(alert: Alert) {
        this.alert = alert;
        (<any>$('#alert-modal-md')).modal();
    }

}
