import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { LeadsService } from '@app/portal/leads/leads.service';
import { ListedLeadsStatus, ListedLeadsStatusResponse } from '@app/portal/leads/models/lead.model';
import { FieldOption } from '@app/portal/settings/field-options/model/field-option.model';
import { Generic } from '@app/shared/models/ems.generic.model';

@Component({
  selector: 'app-agsumo-generic-field-options',
  templateUrl: './agsumo-generic-field-options.component.html',
  styleUrls: ['./agsumo-generic-field-options.component.css']
})
export class AGSumoGenericFieldOptionsComponent implements OnInit {


  @Input() public typeId: number;
  @Input() public valueField = 'id';
  @Input() public name: string;
  @Input() public control: FormControl;
  @Input() public group: FormGroup;
  @Output() changed = new EventEmitter();
  @Input() public module: number|null = null;
  @Input() public titleName: string = 'Options';
  public selectedValues: number[] = [];
  public sumoSelectOptions: any = {};
  public fieldOptions: FieldOption[] = [];
  public ready:boolean = false;
  public loading: boolean = true;
  public leadsStatus: ListedLeadsStatus[] = [];
  public selectedFieldOptions: FieldOption[] = [];

  constructor(
      private leadsService: LeadsService,
      private applicationDataService: ApplicationDataService
  ) { }
  ngOnInit() {
    this.populateFieldOptions();
  }
  addClassOverFlow(event: any){
      if($(event.target).hasClass('overFlowDiv')){
          $(event.target).removeClass('overFlowDiv');
      }
  }
  removeClassOverFlow(event: any){
      if(!$(event.target).hasClass('overFlowDiv')){
          $(event.target).addClass('overFlowDiv');
      }

  }

  public async getListedOptions() {
    if(this.module == 30) {
      this.loading = true;
      const response: ListedLeadsStatusResponse = await this.leadsService.fetchListedLeadStatusAsync();
      if(response.leads_status != "*") {
        this.fieldOptions.forEach((option: FieldOption) => {
          response.leads_status.forEach((lead_status: any) => {
            if(option.name == lead_status.lead_status) {
              this.selectedFieldOptions.push(option);
            }
          });
        })
      } else {
        this.selectedFieldOptions = this.fieldOptions;
      }
      this.loading = false;
    } else {
      this.selectedFieldOptions = this.fieldOptions;
    }
  }

  public getValue(option: FieldOption) {
      return (this.valueField === 'name') ? option.name : option.id;
  }

  public valueChanged($event: any) {
      const value = $event.currentTarget.value;
      if ($event.currentTarget.checked) {
          this.selectedValues.push(value);
      } else {
          this.selectedValues = this.selectedValues.filter(v => v != value);
      }
  }

  public apply() {
      this.changed.emit(this.selectedValues);
  }

  public clear() {
      $('.set-checkbox').prop('checked', false)
      this.selectedValues = [];
      this.changed.emit(this.selectedValues);
  }

  public filterList(event: any) {
      let filter, cardRow, data, i, txtValue;
      filter = event.target.value.toUpperCase();
      cardRow = document.getElementsByClassName('filterdata') as HTMLCollectionOf<HTMLElement>;
      const cards = document.getElementsByClassName('AllRows') as HTMLCollectionOf<HTMLElement>;
      for (i = 0; i < cardRow.length; i++) {
          data = $(cardRow[i]).html();
          if (data) {
              txtValue = data;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                  cards[i].style.display = '';
              } else {
                  cards[i].style.display = 'none';
              }
          }
      }
  }

  private async populateFieldOptions() {
    const fieldOptionTypes = await this.applicationDataService.getFieldOptions();
    this.fieldOptions = fieldOptionTypes[this.typeId].fieldOptions;
    this.getListedOptions();
}

}
