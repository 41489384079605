import { Notification, NotificationTypeEnum } from "../models/notification.model";


export class NotificationBuilder {

    notification: Notification;
    constructor(notification: string) {
        this.notification = JSON.parse(notification);
    }

    public build() {
        const notificationType: NotificationTypeEnum = this.notification.type;
        switch(notificationType) {
            case NotificationTypeEnum.Login:
            case NotificationTypeEnum.Deposit:
            case NotificationTypeEnum.Withdrawal:
                return `Account Alert! <br>${this.notification.message} <i> <br> <a href="/portal/clients/view/${this.notification.data.account.id}">Check it out</a></i>` ;
            break;
            default:
                return this.notification.message;
            break;
        }
    }
}
