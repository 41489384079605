import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { Router } from '@angular/router';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ProfileService } from '../settings/profile/profile.service';
import { User } from '@app/shared/models/user.model';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { GenericEvent } from '@app/shared/models/generic-event.model';
declare const $: any;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AbstractBaseComponent implements OnInit, OnDestroy {
    public user: User;
    constructor(
        public router: Router,
        private authenticationService: AuthenticationService,
        private profileService: ProfileService,
        private applicationEvent: ApplicationEvent) {
        super();
    }

    async ngOnInit() {
        this.subscribe(this.applicationEvent.onGenericEvent.subscribe(this.handleEvent.bind(this)));
        $('#btn-srch').click(function () {
            $('#btn-srch').fadeOut('slow');
        });
        this.user = await this.profileService.getProfileAsync();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }


    public async logoutAsync() {
        await this.authenticationService.logout().toPromise();
    }
    public fading() {
        $('#btn-srch').fadeOut('slow');
        $('.globalSearchDiv').fadeIn('slow');
        this.applicationEvent.fireGenericEvent({ name: 'assignWidth' });
    }
    public fadingClose() {
        $('#btn-srch').fadeIn('slow');
        $('.globalSearchDiv').fadeOut('slow');
    }

    public handleEvent(event: GenericEvent) {
        switch (event.name) {
            case 'fadeIn':
                this.fadingClose();
                break;
            case 'close-user-pref':
                $('#mPreferences').modal('hide');
                break;
        }
    }
    public close() {
        $('#mPreferences').modal('hide');
    }
    public showPreferences() {
        $('#mPreferences').modal('show');
    }
}
