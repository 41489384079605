import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagePreferencesComponent } from './manage-preferences/manage-preferences.component';

@NgModule({
  declarations: [ManagePreferencesComponent],
  imports: [
    CommonModule,
  ],
  exports: [ManagePreferencesComponent],
  entryComponents: [ManagePreferencesComponent]
})
export class UserPreferencesModule { }
