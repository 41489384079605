import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { DynamicScriptLoaderService } from '@app/shared/services/dynamic-script-loader.service';
import { MediaService } from '@app/portal/media/media.service';
import { GenericEvent } from '@app/shared/models/generic-event.model';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
@Component({
  selector: 'app-users-lookup',
  templateUrl: './users-lookup.component.html',
  styleUrls: ['./users-lookup.component.css']
})
export class UsersLookupComponent extends AbstractBaseComponent implements OnInit, OnDestroy {

    @Input()  public type = 'any';
    @Input()  public id = 'lookup';
    @Input()  public showEmail = false;
    @Output() userSelected = new EventEmitter();


    constructor(
        private dynamicScriptLoaderService: DynamicScriptLoaderService,
        private mediaService: MediaService,
        private applicationEvent: ApplicationEvent

    ) { super();}

    ngOnInit() {
        this.subscribe(
            this.applicationEvent.onGenericEvent.subscribe(
              this.handleEvent.bind(this)
            )
          );
        this.initBinding('');
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private formatRepo(user: any) {
        if (user.loading) {
            return 'Loading...';
        }

        const name = user.full_name;
        console.log('Name is',name);

        const markup = '<div class="select2-result-repository__title fs-lg fw-100">' + name + '</div>';
        return markup;
    }

    private processResults(data: any, params: any) {
        params.page = params.page || 1;
        return {
            results: data.results.users,
            pagination:
            {
                more: (params.page * 30) < data.results.length
            }
        };
    }

    private escapeMarkup(markup: any) {
        console.log("Markup values",markup);
        return markup;
    }

    private initBinding(user: string) {
        const this_ = this;
        this.dynamicScriptLoaderService.load(['select2']).then((scripts) => {
        setTimeout(function() {
            const $select2: any = (<any>$)('#' + this_.id + '_lookup');
            $select2.select2({dropdownParent: $('.modal')});
            $select2.select2({
                ajax:
                {
                    url: this_.mediaService.getUploadUrl('search'),
                    dataType: 'json',
                    delay: 500,
                    headers: this_.mediaService.getHeaders(),
                    data: function(params: any) {
                        return {
                            keywords: params.term
                        };
                    },
                    processResults: this_.processResults,
                    cache: true
                },
                placeholder: 'Search users',
                escapeMarkup: this_.escapeMarkup,
                minimumInputLength: 3,
                templateResult: this_.formatRepo,
                templateSelection: function(selection: any) {
                    if (selection.id !== '') {
                        user = null;
                    }
                    if (selection.id === '' && user === null) {
                      return selection.text;
                    } else if (selection.id !== '' && user === null) {
                      this_.userSelected.emit({name: 'user-lookup-selected', payload: selection });
                      return selection.text;
                    }else if (user !== null) {
                      return user;
                    }
                  }
            });
        }, 500);
    });
    }

    private handleEvent(event: GenericEvent) {
        switch (event.name) {
          case 'show-Person':
            this.initBinding(event.payload);
            break;
        }
      }

}
