<div class="row">
    <div class="col-12">
        <div *ngIf="ready">
            <form action="#" [formGroup]="commentForm">
                <div class="form-group row">
                    <div class="col-sm-12 px-4 pt-3">
                        <app-form-error-wrapper [control]="commentForm.controls['comment']" [controlName]="'Comment'">
                            <textarea id="comment" class="form-control" placeholder="Type your comment here..."
                                formControlName="comment"></textarea>
                        </app-form-error-wrapper>
                    </div>
                </div>
                <div class="row my-3 mr-1">
                    <div class="col-12 text-right">
                        <button type="button" class="btn btn-success shadow-0 ml-2" [disabled]="!this.commentForm.valid || httpProcess"
                            (click)="save()"><i class="fal fa-spinner fa-spin" *ngIf="httpProcess"></i> Add Comment</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
