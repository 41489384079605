import { Component } from '@angular/core';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import {AgRendererComponent} from "ag-grid-angular";
@Component({
  selector: 'app-country-flag-cell-component',
  template: `<app-country-flag [width]="30" [country]="value" [showName]="false"></app-country-flag>`
})
export class CountryRendererComponent  implements AgRendererComponent {
    public value: any;
    public ready: boolean = false;
    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

    }

    agInit(params: ICellRendererParams): void {
        this.value = params?.value || null;
    }

}
