import * as moment from "moment";

export interface TimeFrame {
    name: string,
    cssClass: string,
    color: string,
    value: any[]
}

export const TIMEFRAMES_ARRAY: TimeFrame[] = [
    {
        name: 'Today',
        cssClass: 'bg-fusion-50',
        color: 'white',
        value: [
            moment().format('YYYY-MM-DD'),
            moment().add(1,'days').format('YYYY-MM-DD')
        ]
    },
    {
        name: 'This Week',
        cssClass: 'bg-fusion-100',
        color: 'white',
        value: [
            moment().startOf('week').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
        ]
    },
    {
        name: 'This Month',
        cssClass: 'bg-fusion-200',
        color: 'white',
        value: [
            moment().startOf('month').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
        ]
    },
    {
        name: 'Last Month',
        cssClass: 'bg-fusion-300',
        color: 'white',
        value: [
            moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
        ]
    },
    {
        name: '7 days',
        cssClass: 'bg-fusion-400',
        color: 'white',
        value: [
            moment().subtract(7, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
        ]
    },
    {
        name: '14 days',
        cssClass: 'bg-fusion-500',
        color: 'white',
        value: [
            moment().subtract(14, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
        ]
    },
    {
        name: '30 days',
        cssClass: 'bg-fusion-600',
        color: 'white',
        value: [
            moment().subtract(30, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
        ]
    },
    {
        name: '60 day',
        cssClass: 'bg-fusion-700',
        color: 'white',
        value: [
            moment().subtract(60, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
        ]
    },
    {
        name: 'Custom',
        cssClass: 'bg-fusion-800',
        color: 'white',
        value: [
            moment().startOf('month'),
            moment().endOf('month')
        ]
    }
];


export class TimeFrameEnum {
    public TODAY =  TIMEFRAMES_ARRAY[0];
    public THIS_WEEK = TIMEFRAMES_ARRAY[1];
    public THIS_MONTH = TIMEFRAMES_ARRAY[2];
    public LAST_MONTH = TIMEFRAMES_ARRAY[3];
    public SEVEN_DAYS = TIMEFRAMES_ARRAY[4];
    public FOURTEEN_DAYS = TIMEFRAMES_ARRAY[5];
    public THIRTY_DAYS = TIMEFRAMES_ARRAY[6];
    public SIXTY_DAYS = TIMEFRAMES_ARRAY[7];
}
