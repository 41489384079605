import { UserAccessGroupEnum } from "./groups.model";
import { ModuleEnum } from "./module.model";

export enum NavItemType {
    Menu,
    NavTitle
}
export const SettingsSubMenu = [
    {
        id: ModuleEnum.Tools,
        title: 'Tools',
        link: 'javascript:;',
        icon: 'fal fa-cog',
        type: NavItemType.Menu,
        subMenu: [
            // {
            //     id: ModuleEnum.UsersDevices,
            //     title: 'Users Devices',
            //     link: '/portal/settings/user-devices/list',
            //     icon: 'fal fa-server'
            // },
            {
                id: ModuleEnum.SMTPConnections,
                title: 'SMTP Connections',
                link: '/portal/settings/smtp-connections',
                icon: 'fal fa-server'
            },
            {
                id: ModuleEnum.FieldOptions,
                title: 'Field Options',
                link: '/portal/settings/field-options',
                icon: 'fal fa-cogs',
            },
            {
                id:  ModuleEnum.TransactionTypes,
                title: 'Transaction Types',
                link: '/portal/settings/financial-transaction-type',
                icon: 'fal fa-email'
            },
            {
                id:  UserAccessGroupEnum.Administrator,
                title: 'Assignment Rules',
                link: '/portal/settings/assignment-rules',
                icon: 'fal fa-clipboard-list'
            }
        ]
    },
    {
        id: ModuleEnum.Brands,
        title: 'Brands',
        link: '/portal/settings/brands',
        icon: 'fal fa-skyatlas',
    },
    {
        id: ModuleEnum.PromoCodes,
        title: 'Promo Codes',
        link: '/portal/settings/promocode',
        icon: 'fal fa-cogs',
    },
    {
        id: ModuleEnum.TradingPlatforms,
        title: 'Trading Platforms',
        link: '/portal/settings/trading-platform',
        icon: 'fal fa-email'
    },
    {
        id: ModuleEnum.Workflows,
        title: 'Workflows',
        link: '/portal/settings/workflows/list',
        icon: 'fal fa-tasks'
    },
    {
        id: ModuleEnum.SMTPConnections,
        title: 'Default SMTP',
        link: '/portal/settings/default-smtp',
        icon: 'fal fa-at'
    },
    {
        // id:ModuleEnum.Helps,
        title:'Help',
        link:'/portal/settings/helps',
        icon: 'fal fa-cogs',

    },
    {
        id: ModuleEnum.ActivityLog,
        title: 'Activities Log',
        link: '/portal/settings/activities-log',
        icon: 'fal fa-task'
    },
    {
        id: 1003,
        title: 'Login History',
        link: '/portal/settings/login-history',
        icon: 'fal fa-list'
    },
];
