import { Lead } from '@app/shared/models/lead.model';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { ToastrService } from '@app/shared/services/toastr.service';
import { LeadsService } from '../../../portal/leads/leads.service';
import { Account } from '@app/shared/models/account.model';
import { AccountsService } from '@app/portal/accounts/accounts.service';

declare const $: any;
@Component({
  selector: 'app-select-dialer',
  templateUrl: './select-dialer.component.html',
  styleUrls: ['./select-dialer.component.css']
})
export class SelectDialerComponent extends AbstractBaseComponent
  implements OnInit {
  public account: Account;
  public lead: Lead;
  private entityType: string;
  @Output() emitData = new EventEmitter();
  constructor(
    private toastrService: ToastrService,
    private accountsService: AccountsService,
    private leadsService: LeadsService) {
    super();
  }

  ngOnInit() {
    this.ready = true;
  }
  
  public setLead(lead: Lead) {
    this.lead = lead;
  }

  public setAccount(lead: Account) {
    this.account = lead;
  }

  public setEntityType(type: string) {
    this.entityType = type;
  }

  public closeModal() {
    this.emitData.emit({
      name: 'close'
    });
  }

  selectGateway(gateway: string) {
    this.closeModal();
  }

  public async makeCall(gateway: number) {
    try {
        let response: any;
        if(this.entityType == "lead") {
          if(this.lead.has_primary_phone || this.lead.has_mobile) {
            let payload: any = { gateway, agent: 100 };
            payload["lead_id"] = this.lead.id;
            response = await this.leadsService.makeLeadsCall(payload);
          } else {
            this.toastrService.toastError("Lead contact does not exists!");
          }
        } else {
          let payload: any = { gateway, agent: 100 };
          payload["account_id"] = this.account.id;
          response = await this.accountsService.makeCall(payload);
        }
        this.emitData.emit({ name: 'close-modal' });
    } catch (error) {
        this.toastrService.toastError(error.error.message);
    }
  }
}
