export const environment = {
    env: 'PROD',
    production: true,
    serverBaseUrl: 'https://api.crm.digibits.xyz/api/',
    serverUrl: 'https://api.crm.digibits.xyz/api/',
    pusherKey: 'c666ef11-9ca8-4e13-baec-472e2350a2cf',
    pusherHost: 'socket.sellersquadcrm.com',
    pusherPort: 6001,
    pusherCluster: 'mt1',
  };
