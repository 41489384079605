<div class="container h-100">
    <div class="row align-items-center vh-100">
        <div class="col-12">
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <div class="intro-image"></div>
                </div>
                <div  class="col-xs-12 col-md-6">
                    <div class="blankpage-form-field">
                        <div class="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                            <div *ngIf="hasErrors" class="alert bg-danger-400 text-white" role="alert">
                                <strong>Oh snap!</strong> Invalid account information.
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h4 class="text-center mb-3">Reset Password</h4>
                                    <p class="text-muted mb-3">Your new password must be at least 8 characters long and contain at least 6 unique characters.</p>
                                </div>
                            </div>
                            <form id="js-login" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                                <div class="form-group">
                                    <div class="form-group">
                                        <label class="form-label" for="password">Password</label>
                                        <app-form-error-wrapper [control]="resetPasswordForm.controls['password']" [controlName]="'Password'">
                                          <input class="form-control" type="password" formControlName="password" />
                                        </app-form-error-wrapper>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="password_confirmation">Confirm Password</label>
                                        <app-form-error-wrapper [control]="resetPasswordForm.controls['password_confirmation']" [controlName]="'Confirm Password'">
                                          <input class="form-control" type="password" formControlName="password_confirmation" />
                                        </app-form-error-wrapper>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <button type="submit" class="shadow-0 btn btn-success btn-lg btn-block" [disabled]="!resetPasswordForm.valid">Reset Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="alert-modal-md" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 9999">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{alert?.title}}
                    <small class="m-0 text-muted">
                        {{alert?.sub_title}}
                    </small>
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body" [innerHTML]="alert?.body"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{alert?.close_text}}</button>
            </div>
        </div>
    </div>
  </div>
