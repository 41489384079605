import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AccountsService } from '@app/portal/accounts/accounts.service';
import { Generic } from '@app/portal/generics/models/generic.model';
import { LeadsService } from '@app/portal/leads/leads.service';
import { LeadsCountriesResponse, LeadsCuntries } from '@app/portal/leads/models/lead.model';
import { FieldOption } from '@app/portal/settings/field-options/model/field-option.model';
import { COUNTRIES } from '@app/shared/data/countries';
import { AccountsCountries, AccountsCountriesResponse } from '@app/shared/models/account.model';
import { Countries } from '@app/shared/models/country.model';
declare const $: any;
@Component({
    selector: 'app-countries-list-checkboxes-filter',
    templateUrl: './countries-list-checkboxes-filter.component.html',
    styleUrls: ['./countries-list-checkboxes-filter.component.scss']
})

export class CountriesListCheckboxesFilterComponent implements OnInit {

    @Input() public typeId: number;
    @Input() public valueField = 'id';
    @Input() public name: string;
    @Input() public control: FormControl;
    @Input() public group: FormGroup;
    @Output() changed = new EventEmitter();
    @Input() public module: number|null = null;
    public selectedValues: number[] = [];
    public countries: Countries[] = [];
    public sumoSelectOptions: any = {};
    public fieldOptions: Generic[] = [];
    public ready:boolean = false;
    public loading: boolean = true;

    constructor(
        private leadsService: LeadsService,
        private accountService: AccountsService
    ) { }
    ngOnInit() {
        this.getCountries();
    }
    addClassOverFlow(event: any){
        if($(event.target).hasClass('overFlowDiv')){
            $(event.target).removeClass('overFlowDiv');
        }
    }
    removeClassOverFlow(event: any){
        if(!$(event.target).hasClass('overFlowDiv')){
            $(event.target).addClass('overFlowDiv');
        }

    }

    public async getCountries() {
        this.loading = true;
        const countries: Countries[] = COUNTRIES;
        if(this.module) {
            if(this.module == 30) {
                const response: LeadsCountriesResponse = await this.leadsService.fetchCountriesForLeads();
                if(response.leads_countries != '*') {
                    countries.forEach((country: Countries) => {
                       response.leads_countries.forEach((leadCountry: LeadsCuntries) => {
                        if(leadCountry.country == country.name) {
                            this.countries.push(country);
                        }
                       });
                    });
                } else {
                    this.countries = countries;
                }
            } else if (this.module == 1) {
                const response: AccountsCountriesResponse = await this.accountService.getListedCountriesAsnc();
                if(response.countries != '*') {
                    countries.forEach((country: Countries) => {
                       response.countries.forEach((leadCountry: AccountsCountries) => {
                        if(leadCountry.country == country.name) {
                            this.countries.push(country);
                        }
                       });
                    });
                } else {
                    this.countries = countries;
                }
            }
        } else {
            this.countries = countries;
        }
        this.loading = false;
    }

    public getValue(option: FieldOption) {
        return (this.valueField === 'name') ? option.name : option.id;
    }

    public valueChanged($event: any) {
        const value = $event.currentTarget.value;
        if ($event.currentTarget.checked) {
            this.selectedValues.push(value);
        } else {
            this.selectedValues = this.selectedValues.filter(v => v != value);
        }
    }

    public apply() {
        this.changed.emit(this.selectedValues);
    }

    public clear() {
        $('.set-checkbox').prop('checked', false)
        this.selectedValues = [];
        this.changed.emit(this.selectedValues);
    }

    public filterList(event: any) {
        let filter, cardRow, data, i, txtValue;
        filter = event.target.value.toUpperCase();
        cardRow = document.getElementsByClassName('filterdata') as HTMLCollectionOf<HTMLElement>;
        const cards = document.getElementsByClassName('AllRows') as HTMLCollectionOf<HTMLElement>;
        for (i = 0; i < cardRow.length; i++) {
            data = $(cardRow[i]).html();
            if (data) {
                txtValue = data;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    cards[i].style.display = '';
                } else {
                    cards[i].style.display = 'none';
                }
            }
        }
    }
}
