<div class="container mt-2">
  <div class="row mb-4">
    <div class="col-12 d-flex">
      <h5 class="font-weight-bold">
        Dashboard Setting
      </h5>
      <button type="button" class="btn btn-success btn-xs ml-auto mr-3 shadow-0" (click)="saveUserPref($event)"><i
          class="fal fa-spinner fa-spin" *ngIf="httpProcess"></i> Save</button>
    </div>
  </div>
  <!-- User Widgets Setting -->
  <div class="row">
    <div class="col-7">
      <label id="selectAllLabel" for="selectAll">Quick Action</label>
      <p class="text-muted pmargin">Select/Deselect All Widgets</p>
    </div>
    <div class="col-5">
      <div class="custom-control custom-switch custom-control-inline float-right">
        <input type="checkbox" (change)="changeAll($event)" class="custom-control-input text-right" id="selectAll">
        <label class="custom-control-label" for="selectAll"></label>
      </div>
    </div>
  </div>
  <div class="row" *ngFor="let up of user_Preferences">
    <div class="col-7">
      <label id="{{up.labelId}}" for="{{up.id}}">{{up.labelName}}</label>
      <p class="text-muted pmargin">Widget</p>
    </div>
    <div class="col-5">
      <div class="custom-control custom-switch custom-control-inline float-right">
        <input name="widget" type="checkbox" (change)="change($event)" class="custom-control-input text-right"
          id="{{up.id}}">
        <label class="custom-control-label" for="{{up.id}}"></label>
      </div>
    </div>
  </div>
  <!--End User Widgets Setting -->

  <!-- User Currency Setting -->
  <div class="row mb-4 mt-2">
    <div class="col-12 d-flex">
      <h5 class="font-weight-bold">
        Currency Setting
      </h5>
    </div>
  </div>
  <div class="row" *ngFor="let currency of curriences">
    <div class="col-7">
      <label id="{{currency.id}}label" class="customMargin" for="{{currency.id}}"
        >{{currency.name}}</label>
      <p class="text-muted pmargin">Currency</p>
    </div>
    <div class="col-5">
      <div class="custom-control custom-switch custom-control-inline float-right">
        <input type="checkbox" name="currency" (change)="currencyChange($event)" class="custom-control-input text-right"
          id="{{currency.id}}">
        <label class="custom-control-label" for="{{currency.id}}"></label>
      </div>
    </div>
  </div>
  <!-- End User Currency Setting -->
</div>