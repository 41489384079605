import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService, LocalStorageService } from '@app/core';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { Credentials } from '@app/core/model/authentication.model';
import { SetupStatus } from '@app/core/model/setup.model';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { UserPrefResponse } from '@app/portal/user-preferences/user-pref-model/user-pref.model';

declare const $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AbstractBaseComponent implements OnInit {
  loginForm: FormGroup;
  resetPasswordForm: FormGroup;
  public paymentNeeded = false;
  public accountSignupEmailSent = false;
  public userPreferences: any = {
    deposit_by_day: true,
    latest_activities: true,
    deposit_by_assignee: true,
    monthly_deposit_withdraw: true,
    daily_deposit_withdraw: true,
    lead_by_status: true,
    ticket_by_status: true,
    clients_by_status: true,
    deposit_by_countries: true,
    preferred_currency: 'USD',
  };
  @ViewChild('loginBtn') loginBtn: ElementRef;
  @ViewChild('autofocus') email: ElementRef;

  public loginFormData: { id?: number; email: string; password: string } = null;


  isLoading = false;
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private applicationDataService: ApplicationDataService,
    private localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService) {
      super();
  }

    ngOnInit() {
        this.createForm();
        const params = this.router.parseUrl(this.router.url).queryParams;
        if(!!params.signup && params.signup === 'done') {
            this.accountSignupEmailSent = true;
        }
    }

    ngAfterViewInit() {
      setTimeout(() => {
        this.email.nativeElement.focus();
      }, 500);
    }

    async login() {
    this.httpProcessStart();
        this.loadingStart();
        this.loginFormData = this.loginForm.value;
        this.authenticationService
          .login(this.loginForm.value)
          .subscribe(
            async (credentials: Credentials) => {
              await this.profileService.getProfileAsync().then( async () => {
                setTimeout(async () => {
                    const user = await this.profileService.getUser();
                    this.router.navigate(['/portal/dashboard'], {
                        replaceUrl: true
                    });
                    window.location.reload();
                    this.loadingFinished();
                    this.httpProcessEnd();
                }, 200);
              });
            }, (errorRequest) => {
                this.loadingFinished();
                this.httpProcessEnd();

                this.errorsList = this.applicationDataService.getRequestErrors(errorRequest);
                if(errorRequest.status===429){
                    this.errorsList.push(errorRequest.statusText);
                }
                if(errorRequest.error.otp_required) {
                    $("#2fa-modal-md").modal();
                }
            }
        );
  }

  resetPassword() {
    // TODO: Implement Reset Password
  }
  public userPrefs(payload: any, id: string) {
    const payload2: any = JSON.parse(payload);
    let userPrefsize = 0;
    let defaultPrefsize = 0;
    for (const key in payload2) {
      if (payload2.hasOwnProperty(key)) {
        userPrefsize++;
      }
    }
    for (const key in this.userPreferences) {
      if (this.userPreferences.hasOwnProperty(key)) {
        defaultPrefsize++;
      }
    }
    if (userPrefsize < defaultPrefsize) {
      this.assignValue(payload2['deposit_by_day'], 'currency');
      const userPrefs: any = {
        deposit_by_day:  this.assignValue (payload2['deposit_by_day'], 'bool'),
        latest_activities:  this.assignValue(payload2['latest_activities'], 'bool'),
        deposit_by_assignee:  this.assignValue(payload2['deposit_by_assignee'], 'bool'),
        monthly_deposit_withdraw:  this.assignValue(payload2['monthly_deposit_withdraw'], 'bool') ,
        daily_deposit_withdraw: this.assignValue(payload2['daily_deposit_withdraw'], 'bool'),
        lead_by_status: this.assignValue(payload2['lead_by_status'], 'bool'),
        ticket_by_status: this.assignValue(payload2['ticket_by_status'], 'bool'),
        clients_by_status: this.assignValue(payload2['clients_by_status'], 'bool'),
        deposit_by_countries:this.assignValue(payload2['deposit_by_countries'], 'bool'),
        preferred_currency: this.assignValue(payload2['preferred_currency'], 'currency'),
      };
      const savepayload: any = {};
      savepayload['data'] =  JSON.stringify(userPrefs);
      this.localStorageService.setItem(id, JSON.stringify(userPrefs));
      this.localStorageService.setItem('currency', userPrefs['preferred_currency']);
      this.profileService.savePreferences(savepayload);
    } else if (userPrefsize === defaultPrefsize) {
      this.localStorageService.setItem( id, payload);
      this.localStorageService.setItem('currency', payload2['preferred_currency']);
    }
  }

  public assignValue(value: any, type: string) {
    if (value === undefined && type === 'currency') {
        return 'USD';
    } else if (value === undefined && type === 'bool') {
      return true;
    } else if (type === 'bool' || type === 'currency' ) {
      return value;
    }
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required]
    });
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required]]
    });
  }

  public test() {
    console.log("work is fine");
  }
}
