import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ShowEncryptDataPayload } from '@app/shared/models/show-data.model';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { ShowEncryptService } from '@app/shared/services/show-encrypt.service';
import { ToastrService } from '@app/shared/services/toastr.service';

@Component({
  selector: 'app-show-encrypted-data',
  templateUrl: './show-encrypted-data.component.html',
  styleUrls: ['./show-encrypted-data.component.css']
})
export class ShowEncryptedDataComponent extends AbstractBaseComponent implements OnInit {
    @Output() emitData = new EventEmitter();
    public payloadData: ShowEncryptDataPayload;
    public data: string;

    public detail: any;

    constructor(protected showEncryptService: ShowEncryptService,
    protected toastrService: ToastrService) {
        super();
    }

    ngOnInit() {
    }

    public async setDetail(detail: any) {
        try {
            this.loadingStart();
            this.detail = detail;
            console.log(detail);
            this.payloadData = {
                module_id: this.detail.colDef.module_id,
                field: this.detail.colDef.field,
                data: this.detail.data
            };
            this.data = await this.showEncryptedData(this.payloadData);
            this.loadingFinished();
        } catch (error) {
            this.toastrService.toastError(this.detail.colDef.headerName + ' is Empty.');
            this.close();
        }
    }

    public close() {
        this.emitData.emit({name: 'close'});
    }

    public async showEncryptedData(payload: ShowEncryptDataPayload) {
        return await this.showEncryptService.showEncryptDataAsync(payload);
    }

}
