import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ApplicationEvent } from './shared/services/alert-modal.service';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader.service';
import { environment } from '@env/environment';
import Pusher from "pusher-js";
import Echo from "laravel-echo";

declare const window: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit, OnInit {
    genericEvent: any;
    constructor(private applicationEvent: ApplicationEvent,
                private dynamicScriptLoaderService: DynamicScriptLoaderService) {
    }

    ngOnInit(): void {
        window.Pusher = Pusher;
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: environment.pusherKey,
            forceTLS: false,
            encrypted: false,
            enabledTransports: ['ws'],
            cluster: environment.pusherCluster,
            wsHost: environment.pusherHost,
            wsPort: environment.pusherPort
        });

        window.Echo.channel('crm-notifications').listen('RealTimeNotificationsEvent', (notification: any)=> {
            this.applicationEvent.fireNotificationEvent(notification);
        });
    }

    ngAfterViewInit() {
        const scriptsToLoad: string[] = [
            'flot-charts',
        ];
        this.dynamicScriptLoaderService.load(scriptsToLoad).then((_) => {
            this.applicationEvent.fireGenericEvent({whatHappened: 'scripts-loaded'});
        });
    }
}
