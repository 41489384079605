import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { DynamicScriptLoaderService } from '@app/shared/services/dynamic-script-loader.service';
import { MediaService } from '@app/portal/media/media.service';
import { GenericEvent } from '@app/shared/models/generic-event.model';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';

@Component({
  selector: 'app-accounts-lookup',
  templateUrl: './accounts-lookup.component.html',
  styleUrls: ['./accounts-lookup.component.css']
})
export class AccountsLookupComponent extends AbstractBaseComponent implements OnInit, OnDestroy {

    @Input()  public type = 'any';
    @Input()  public id = 'lookup';
    @Input()  public showEmail = false;
    @Output() accountSelected = new EventEmitter();


    constructor(
        private dynamicScriptLoaderService: DynamicScriptLoaderService,
        private mediaService: MediaService,
        private applicationEvent: ApplicationEvent

    ) { super();}

    ngOnInit() {
        this.subscribe(
            this.applicationEvent.onGenericEvent.subscribe(
              this.handleEvent.bind(this)
            )
          );
        this.initBinding('');
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private formatRepo(account: any) {
        if (account.loading) {
            return 'Loading...';
        }

        const name = account.description;
        const id = account.id;
        const markup = '<div class="select2-result-repository__title fs-lg fw-100">('+id+') ' + name + '</div>';
        return markup;
    }

    private processResults(data: any, params: any) {
        params.page = params.page || 1;
        return {
            results: data.results.accounts,
            pagination:
            {
                more: (params.page * 30) < data.results.length
            }
        };
    }

    private escapeMarkup(markup: any) {
        return markup;
    }

    private initBinding(account: string) {
        const this_ = this;
        this.dynamicScriptLoaderService.load(['select2']).then((scripts) => {
        setTimeout(function() {
            const $select2: any = (<any>$)('#' + this_.id + '_lookup');
            $select2.select2({dropdownParent: $('.modal')});
            $select2.select2({
                ajax:
                {
                    url: this_.mediaService.getUploadUrl('accounts/search_account'),
                    dataType: 'json',
                    delay: 500,
                    headers: this_.mediaService.getHeaders(),
                    data: function(params: any) {
                        return {
                            keywords: params.term,
                        };
                    },
                    processResults: this_.processResults,
                    cache: true
                },
                placeholder: 'Search accounts',
                escapeMarkup: this_.escapeMarkup,
                minimumInputLength: 3,
                templateResult: this_.formatRepo,
                templateSelection: function(selection: any) {
                    console.log(selection);
                    if (selection.id !== '') {
                        account = null;
                    }
                    if (selection.id === '' && account === null) {
                      return selection.text;
                    } else if (selection.id !== '' && account === null) {
                      this_.accountSelected.emit({name: 'account-lookup-selected', payload: selection });
                      return selection.description;
                    }else if (account !== null) {
                      return account;
                    }
                  }
            });
        }, 500);

    });

    }

    private handleEvent(event: GenericEvent) {
        switch (event.name) {
          case 'show-Person':
            this.initBinding(event.payload);
            break;
        }
      }

}
