<div class="modal-header">
    <h4 class="modal-title">Select your dialer</h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">&times;</button>
</div>
<div class="modal-body">
    <div class="d-flex justify-content-center flex-wrap">
        <button class="btn btn-light d-flex justify-content-center align-items-center m-2 flex-column" (click)="makeCall(2)">
            <img class="icon-image mb-1" src="assets/images/coperato-logo.png" alt="">
            <h5 class="card-title text-uppercase mb-0">Coperato</h5>
        </button>
        <button class="btn btn-light d-flex justify-content-center align-items-center m-2 flex-column" (click)="makeCall(1)">
            <img class="icon-image mb-1" src="assets/images/viso.logo.webp" alt="">
            <h5 class="card-title text-uppercase mb-0">Voiso</h5>
        </button>
    </div>
    <div class="row" *ngIf="ready">
        <div class="col text-right mt-3">
            <button type="button" class="btn btn-sm btn-outline-default shadow-0" (click)="closeModal()">Cancel</button>
        </div>
    </div>
</div>
