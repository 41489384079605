<div class="modal fade outlet-switcher-modal modal-backdrop-transparent" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-right modal-md">
      <div class="modal-content">
        <div class="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center w-100">
            <h4 class="m-0 text-center color-white">
                Switch Outlet
                <small class="mb-0 opacity-80">Choose a different outlet to manage</small>
            </h4>
            <button type="button" class="close text-white position-absolute pos-top pos-right p-2 m-1 mr-2"
                data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="p-3">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <p>Switch To:</p>
                            <div id="js_default_list" class="js-list-filter">
                                <ul class="list-group">
                                    <li class="list-group-item" *ngFor="let outlet of outlets" (click)="switchOutlet(outlet)">
                                        <span>{{outlet.name}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
