import { Component, Input, OnInit } from '@angular/core';
import { ApplicationDataService } from '@app/core/service/application-data.service';

@Component({
  selector: 'app-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.css']
})
export class CountryFlagComponent implements OnInit {

    @Input() public country: string = "";
    @Input() public width = 20;
    @Input() public height = 20;
    @Input() public showName = false;

    public flag: string = "";
    constructor(private applicationDataService: ApplicationDataService) { }
    ngOnInit() {
        this.flag = this.applicationDataService.getCountryFlag(this.country);
    }
}
