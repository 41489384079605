import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProfileService } from '../settings/profile/profile.service';
import { CommentsService } from './comments.service';
import { CommentsFilter, CommentsResponse, Comment } from '@app/shared/models/comment.model';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { GenericEvent } from '@app/shared/models/generic-event.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from '@app/shared/services/toastr.service';
declare const $: any;
@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class ListCommentsComponent extends AbstractBaseComponent implements OnInit, OnDestroy {

    @Input() public module: string;
    @Input() public module_id: number;
    @Input() public relation_id: number;
    @Input() public relation_ids: number[] = [];
    public comments: Comment[] = [];
    private bsModalRef: BsModalRef;


    constructor(protected commentsService: CommentsService,
        protected toastrService: ToastrService,
        protected applicationEvent: ApplicationEvent) {
        super();
        this.subscribe(this.applicationEvent.onGenericEvent.subscribe(this.handleEvent.bind(this)));
    }

    ngOnInit(): void {
        this.fetchCommentsAsync();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public async fetchCommentsAsync() {
        try {
            const commentsFilters: CommentsFilter = { module: this.module, relation_id: this.relation_id, module_id: this.module_id };
            const response: CommentsResponse = await this.commentsService.fetchCommentsAsync(commentsFilters);
            this.comments = response.comments.data;
            this.applicationEvent.fireGenericEvent('comments-loaded');
            this.ready = true;
            this.initSlimScroll();
        } catch (error) {
            if (error.constructor.name !== 'HttpErrorResponse') {
                this.toastrService.toastError(error);
            }
        }
    }

    private handleEvent(event: GenericEvent) {
        switch (event.name) {
            case 'comment-created':
                this.handleNewCommentAdded(event.payload);
                break;
            case 'close':
                this.bsModalRef.hide();
                break;
                case 'close-modal':
                $('.comment-modal').modal('hide');
                break;
        }
    }

    public initSlimScroll() {
        $('.custom-scroll').slimScroll({
            position: 'right',
            height: '300px',
            railVisible: true,
            alwaysVisible: true
        });
    }

    private handleNewCommentAdded(comment: Comment) {
        this.comments.unshift(comment);
        $('.comments-wrapper').scrollTop(0);
    }

}
