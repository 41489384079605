import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { AccountsService } from '@app/portal/accounts/accounts.service';
import { LeadsService } from '@app/portal/leads/leads.service';
import { ListedLeadsAssigness, ListedLeadsAssignessResponse } from '@app/portal/leads/models/lead.model';
import { FieldOption } from '@app/portal/settings/field-options/model/field-option.model';
import { ListedAcccountDocumentStatus, ListedAcccountStatus, ListedAccountsDocumentStatusResponse, ListedAccountsStatusResponse } from '@app/shared/models/account.model';

@Component({
    selector: 'app-ag-set-filter',
    templateUrl: './ag-set-filter.component.html',
    styleUrls: ['./ag-set-filter.component.scss']
})

export class AgSetFilterComponent implements OnInit {

    @Input() public name: string;
    @Input() public valueField = 'id';
    @Input() public filterTitle: string = '';
    @Output() changed = new EventEmitter();
    @Input() public options: any[] = [];
    @Input() public module: any = null;
    @Input() public type: any = null;
    public loading = false;
    public selectedOptions: FieldOption[] = [];
    public ftdStatus: any[] = [
        {
            id: 'Approved',
            name: 'Yes'
        },
        {
            id: 'Partial',
            name: 'Partial'
        },
        {
            id: 'Not Approved',
            name: 'No'
        },
    ]

    public selectedValues: any[] = [];
    constructor(
        private accountService: AccountsService,
        private applicationDataService: ApplicationDataService,
        private leadService: LeadsService
    ) { }
    ngOnInit() {
        setTimeout(()=>{
            this.readyOptions();
        }, 500)
    }

    public getValue(option: any) {
        return (this.valueField === 'name') ? option.name : option.id;
    }

    public valueChanged($event: any) {
        const value = $event.currentTarget.value;
        if ($event.currentTarget.checked) {
            this.selectedValues.push(value);
        } else {
            this.selectedValues = this.selectedValues.filter(v => v != value);
        }
    }

    public apply() {
        this.changed.emit(this.selectedValues);
    }

    public clear() {
        $('.' + this.name + '-set-checkbox').prop('checked', false)
        this.selectedValues = [];
        this.changed.emit(this.selectedValues);
    }
    public filterList(event: any) {
        let filter, cardRow, data, i, txtValue;
        filter = event.target.value.toUpperCase();
        cardRow = document.getElementsByClassName('filterdata') as HTMLCollectionOf<HTMLElement>;
        const cards = document.getElementsByClassName('AllRows') as HTMLCollectionOf<HTMLElement>;
        for (i = 0; i < cardRow.length; i++) {
            data = $(cardRow[i]).html();
            if (data) {
                txtValue = data;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    cards[i].style.display = '';
                } else {
                    cards[i].style.display = 'none';
                }
            }
        }
    }

    public async readyOptions() {
        if(this.module == 1 && this.type == 41) {
            this.loading = true;
            this.options = await this.applicationDataService.getFieldOptionsByTypeId(this.type);
            const response: ListedAccountsStatusResponse = await this.accountService.getListedStatusAsnc();
            if(response.account_status != '*') {
                response.account_status.forEach((accountStatus: ListedAcccountStatus) => {
                    this.options.forEach((fieldOption: FieldOption) => {
                        if(accountStatus.account_status== fieldOption.name) {
                            this.selectedOptions.push(fieldOption);
                        }
                    });
                });
            } else {
                this.selectedOptions = this.options;
            }
            this.loading = false;
        } else if(this.module == 1 && this.type == 'ftd') {
            this.loading = true;
            this.options = this.ftdStatus;
            const response: ListedAccountsDocumentStatusResponse = await this.accountService.getListedDocumentStatusAsnc();
            if(response.document_status != '*') {
                response.document_status.forEach((accountStatus: ListedAcccountDocumentStatus) => {
                    this.options.forEach((fieldOption: FieldOption) => {
                        if(accountStatus.document_status == fieldOption.name) {
                            this.selectedOptions.push(fieldOption);
                        }
                    });
                });
            } else {
                this.selectedOptions = this.options;
            }
            this.loading = false;
        } else if (this.module == 30 && this.type == 'assigne'){
            this.loading = true;
            this.options = await this.applicationDataService.getFieldOptionsByTypeId(this.type);
            const response: ListedLeadsAssignessResponse = await this.leadService.fetchListedLeadsAssignee();
            if(response.assigned_to_names != '*') {
                response.assigned_to_names.forEach((accountStatus: ListedLeadsAssigness) => {
                    this.options.forEach((fieldOption: FieldOption) => {
                        if(accountStatus.assigned_to_name == fieldOption.name) {
                            this.selectedOptions.push(fieldOption);
                        }
                    });
                });
            } else {
                this.selectedOptions = this.options;
            }
            this.loading = false;
        } else {
            this.selectedOptions = this.options;
        }
    }
}
