import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-records',
  templateUrl: './no-records.component.html',
  styleUrls: ['./no-records.component.css']
})
export class NoRecordsComponent implements OnInit {
    @Input() public height = '200px';
    @Input() public text: string = 'No Records';
    @Input() public image = true;
    constructor() { }

    ngOnInit() {
    }

    get style() {
        return { height: this.height };
    }

}
