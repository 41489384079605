import { EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert } from '@app/shared/models/alert.model';
import { Subscription } from 'rxjs';
import { User } from '@app/shared/models/user.model';
import { OnDestroy } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { StringMap } from '@app/shared/models/common.model';
import { ModuleEnum } from '@app/shared/models/module.model';
import { Pagination } from '@app/shared/models/pagination.model';
import { FieldOption } from '@app/portal/settings/field-options/model/field-option.model';
import { DashboardPayloadData } from '@app/shared/models/dashboard-payload-data.model';

declare const $: any;

export abstract class AbstractBaseComponent implements OnDestroy {
    public pageTitle = 'Abstract';
    public subTitle = 'Gmail';
    public pageIcon = 'fal fa-chart-area';
    public hasErrors = false;
    public errorsList: string[] = [];
    public employee: User;
    public httpError: HttpErrorResponse;
    public errorSubscription: any;
    public applicationEventSubscription: any;
    public alertModalSubscription: any;
    public onError = new EventEmitter<any>();
    public ready = false;
    public alert: Alert;
    public loading = false;
    public httpProcess = false;
    protected subscriptions: Subscription[] = [];
    protected initializedDataTables: string[] = [];
    private tableOptions: StringMap[] = [];
    public dataTableName = 'datatable';
    protected dataTableInitialized = false;
    public massIds: number[] = [];
    public pages: number[] = [];
    public total_db_records: any = [{}];
    public pagination: Pagination = {
        id: '',
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: 10
    };
    public emptyChart = false;

    // Table Settings Data
    public gridApi: any;
    public gridColumnApi: any;
    public rowCount: any;
    public gridOptions: any;

    //Dashboard Data
    public chart: any;
    public dashboardPayloadData: DashboardPayloadData;
    public currentCurrency: FieldOption;
    public chartData: number[] = [];
    public chartLabels: string[] = [];

    constructor() {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription = null;
        });
    }

    protected subscribe(subscription: Subscription) {
        if (!this.subscriptions.includes(subscription)) {
            this.subscriptions.push(subscription);
        }
    }

    protected unsubscribe() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    protected updatePagerLinks(page: number) {
        $('.page-item').removeClass('active');
        $('.page-item:eq(' + (page - 1) + ')').addClass('active');
    }

    protected isAdmin() {
        return false;
    }

    protected initDataTable(tableName: string = '') {
        const tableToBeInit = (tableName === '') ? this.dataTableName : tableName;
        const tableOptions = this.getTableOptions();
        const this_ = this;
        setTimeout(function () {
            const table: any = $('#' + tableToBeInit);
            if (this_.initializedDataTables.indexOf(tableToBeInit) >= 0) {
                table.DataTable();
            } else {
                this_.initializedDataTables.push(tableToBeInit);
                table.DataTable(tableOptions);
            }
        }, 500);
    }

    protected addTableOption(name: string, value: any) {
        this.tableOptions.push({ name, value });
    }

    protected clearTableOptions() {
        this.tableOptions = [];
    }

    protected getTableOptions() {
        if (this.tableOptions.length === 0) {
            return {
                scrollY: '500px',
                scrollCollapse: true
            };
        } else {
            const tableOptions: StringMap = {};
            this.tableOptions.forEach(option => {
                tableOptions[option.name] = option.value;
            });
            return tableOptions;
        }
    }

    protected setPages(pages: number) {
        this.pages = [];
        for (let i = 1; i <= pages; i++) {
            this.pages.push(i);
        }
    }
    protected async totalRecords (records: number) {
        const totalRecords = [{}];
        let page = 1;
        for (let i: any = 1; i < records + 1; i++ ) {
            if ( i % 10 === 0) {
                page ++;
            }
            totalRecords.push({record: i, page: page});
        }
        this.total_db_records = totalRecords;
    }

    protected addDataTableButtons() {
        this.addTableOption('dom', '<\'row mb-3\'<\'col-sm-12 col-md-12 d-flex align-items-center justify-content-start\'f>' +
            '<\'col-sm-12 col-md-12 d-flex align-items-center justify-content-start\'B>>' +
            '<\'row\'<\'col-sm-12\'tr>>' +
            '<\'row\'<\'col-sm-12 col-md-5\'i><\'col-sm-12 col-md-7\'p>>');
        this.addTableOption('buttons', [

        ]);
    }

    protected loadingStart() {
        this.loading = true;
    }

    protected loadingFinished() {
        this.loading = false;
    }

    protected httpProcessStart() {
        this.httpProcess = true;
    }

    protected httpProcessEnd() {
        this.httpProcess = false;
    }

    protected removeRow(rowId: string, dataTableName: string) {
        const table: any = $('#' + dataTableName);
        $('#' + rowId).remove();
        // table.draw();
    }

    protected removeElementByClass(clazzNmae: string) {
        $('.' + clazzNmae).remove();
    }

    protected removeElementById(id: string) {
        $('#' + id).remove();
    }

    protected check(obj: any, idKey: string = 'id') {
        const id = obj[idKey];
        const idIndex = this.massIds.indexOf(id);
        if (idIndex == -1) {
            this.massIds.push(id);
        } else {
            this.massIds.splice(idIndex, 1);
        }
    }

    protected resetMassIds() {
        this.massIds = [];
    }

    getFormValidationErrors(form: any) {
        const errors: string[] = [];
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    errors.push('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
        return errors;
    }

    public runJqueryBindings() {
        $('.custom-scroll').each(function () {
            $(this).slimScroll({
                railVisible: false,
                height: $(this).attr('data-scrollHeight') + 'px',
                alwaysVisible: false,
            });
        });
    }

    public createRecord() {

    }

    public save() {

    }

    public getValue(obj: any, val: any, defaultValue: any = null): any {
        return (obj == null || !obj.hasOwnProperty(val)) ? defaultValue : obj[val];
    }

    public getMassIds() {
        return this.massIds;
    }

    public get moduleEnum() {
        return ModuleEnum;
    }

    showHide(id: string) {
        if ($('#' + id + ' i').hasClass('fal fa-minus')) {
            $('#' + id + ' i').removeClass('fal fa-minus');
            $('#' + id + ' i').addClass('fal fa-plus');
        } else {
            $('#' + id + ' i').removeClass('fal fa-plus');
            $('#' + id + ' i').addClass('fal fa-minus');
        }
    }

    public chartIsEmpty(data: any[]) {
        const isEmpty: boolean[] = data.map(el => {
            if (el > 0) {
                return true;
            } else {
                return false;
            }
        });
        if (isEmpty.includes(true)) {
            this.emptyChart = false;
        } else {
            this.emptyChart = true;
        }

    }

    public ucWords(str: string) {
        return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
            return $1.toUpperCase();
        });
    }

    public replaceAll(str: string, find: string, replace: any) {
        const escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
        return str.replace(new RegExp(escapedFind, 'g'), replace);
    }
}
