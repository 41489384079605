import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { Alert } from '@app/shared/models/alert.model';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent extends AbstractBaseComponent implements OnInit {

    resetPasswordForm: FormGroup;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private profileService: ProfileService) {
      super();
    }

    ngOnInit() {
        this.createForm();
    }

    private createForm() {
        this.resetPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    public async requestPasswordResetEmail() {
        this.loadingStart();
        try {
            const resp = await this.profileService.generatePasswordResetEmail(this.resetPasswordForm.value.email);
            const alert: Alert = {title: 'Password Recovery',
                                  sub_title: 'Request completed',
                                  body: 'We have sent you an email with instructions to reset your password, please check your email.',
                                  ok_text: 'OK',
                                  close_text: 'CLOSE'};
            this.handleModalAlert(alert);
            this.loadingFinished();
        } catch (error) {
            this.hasErrors = true;
            this.httpError = error;
        }
        this.loadingFinished();
    }

    public handleModalAlert(alert: Alert) {
        this.alert = alert;
        (<any>$('#alert-modal-md')).modal();
    }

    public closeModal() {
        this.router.navigate(['login'], {
            queryParams: {
                forgot_password: 'done'
        }});
    }
}
