import { Component } from '@angular/core';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { IFloatingFilter } from 'ag-grid-community';

@Component({
  selector: 'number-component',
  template: `<app-countries-list-checkboxes-filter [module]="this.module" [valueField]="'name'" (changed)="onParentModelChanged($event)"></app-countries-list-checkboxes-filter>`,
})
export class CountriesSetFilterComponent implements IFloatingFilter, AgFrameworkComponent<any> {
    params: any;
    currentValue: number[] = [];
    style: any;
    module: number|null = null;

    agInit(params: any): void {
        this.params = params;
        this.module = this.params.colDef.module ? this.params.colDef.module : null;
    }

    onParentModelChanged(model: any) {
        this.currentValue = model;
        this.params.filterChangedCallback();
    }

    getModel() {
        return {
            filterType: 'array',
            type: 'contains',
            filter: this.currentValue,
        }
    }

    isFilterActive() {
        return this.currentValue && this.currentValue.length > 0;
    }
}
