import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { GlobalSearch } from './models/global-search.model';
@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService extends BaseAPIClass {
  private readonly URL_GLOBAL_SEARCH: string = 'search';
  constructor(
    protected httpClient: HttpClient,
    protected authenticationService: AuthenticationService) {
    super(httpClient, authenticationService);
}
  public async globalSearch(keyword: GlobalSearch) {
    return await this.getAsync<any>(this.URL_GLOBAL_SEARCH, keyword, {}, false).toPromise();
  }
}
