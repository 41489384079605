import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AbstractBaseComponent } from "@app/core/class/abstract.base.omponent";
import { ApplicationEvent } from "@app/shared/services/alert-modal.service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { Subject } from "rxjs/internal/Subject";
import { GlobalSearchService } from "../global-search.service";
import { GlobalSearch as GlobalSearch, SearchRecord, SearchResults } from "../models/global-search.model";

declare const $: any;
@Component({
    selector: 'app-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent extends AbstractBaseComponent implements OnInit, OnDestroy {

    public globalSearch: GlobalSearch = {
        keywords: null
    };
    public searchResults: SearchResults = {};
    public searching = false;
    public moduleResultNotFound = false;
    private subject: Subject<GlobalSearch> = new Subject();
    public moduleProperties: any = {
        users: {
            name: 'Users',
            icon: 'fal fa-user',
            color: '#fc0'
        },
        accounts: {
            name: 'Accounts',
            icon: 'fal fa-shield-alt',
            color: '#5e183d'
        },
        tradingaccounts: {
            name: 'Trading Accounts',
            icon: 'fa-trophy-alt fal',
            color: '#006666'
        },
        leads: {
            name: 'Leads',
            icon: 'fa-dot-circle fal',
            color: '#f7b392'
        },
    };


    constructor(
        private globalSearchService: GlobalSearchService,
        private router: Router,
        private applicationEvent: ApplicationEvent) {
        super();
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngOnInit() {
        this.subscribe(this.applicationEvent.onGenericEvent.subscribe(this.handleEvent.bind(this)));
        this.subscribeForSearch();
        $(document).mouseup((e: any) => {
            const container = $('#txt');
            const container2 = $('#dropDown');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                if (!container2.is(e.target) && container2.has(e.target).length === 0) {
                    this.hide();
                }
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public style(event: any) {
        $('.dropDown').show();
        if ($(document).width() > 750) {
            $('.txt, .dropDown').removeClass('resetAnimation');
            $('.txt, .dropDown').addClass('doAnimation');
        }
    }

    public hide() {
        if ($(document).width() > 750) {
            $('.txt, .dropDown').removeClass('doAnimation');
            $('.txt, .dropDown').addClass('resetAnimation');
            setTimeout(() => {
                $('.txt, .dropDown').removeClass('resetAnimation');
            }, 3000);
        }

        $('.dropDown').hide();
        if ($(document).width() < 992) {
            this.applicationEvent.fireGenericEvent({
                name: 'fadeIn'
            });
        }
    }

    public async search(event: any) {
        if (event.target.value !== '' && event.target.value.length >= 3) {
            this.globalSearch.keywords = event.target.value;
            this.subject.next(this.globalSearch);
        } else {
            this.searching = false;
            this.searchResults = {};
            this.loadingFinished();
            this.moduleResultNotFound = false;
        }
    }

    public searchHasAccounts() {
        return (this.searchResults != null && this.searchResults?.results && this.searchResults.results.accounts.length > 0);
    }

    public getListOfModules() {
        let modulesList: string[] = [];
        if (this.searchResults != null && this.searchResults?.results) {
            modulesList = Object.keys(this.searchResults?.results);
        }
        return modulesList;
    }

    public checkIfNoRecords() {
        const modules = Object.keys(this.searchResults?.results);
        let modulesLength = false;
        for (let i: any = 0; i < modules.length; i++) {
            const moduleResults = this.getValue(this.searchResults.results, modules[i]);
            if (moduleResults.length > 0) {
                modulesLength = true;
            }
        }
        if (!modulesLength) {
            this.moduleResultNotFound = true;
            if ($('#txt').val().length < 3) {
                this.moduleResultNotFound = false;
            }
        }
        if ($('#txt').val().length < 3) {
            this.moduleResultNotFound = false;
            this.searchResults = {};
        }
    }

    public getModuleName(moduleKey: string) {
        return this.moduleProperties[moduleKey].name || moduleKey;
    }

    public getModuleIcon(moduleKey: string) {
        return this.moduleProperties[moduleKey].icon || 'fa-file-alt fal';
    }

    public getModuleIconColor(moduleKey: string) {
        return this.moduleProperties[moduleKey].color || '#333';
    }

    public hasResults(moduleKey: any) {
        if (this.searchResults != null && this.searchResults?.results) {
            const moduleResults = this.getValue(this.searchResults.results, moduleKey);
            return moduleResults.length > 0;
        }
        return false;
    }

    public getResults(moduleKey: any) {
        let results = [];
        if (this.searchResults != null && this.searchResults?.results) {
            results = this.getValue(this.searchResults.results, moduleKey);
        }
        return results;
    }

    public setWidth() {
        if ($(document).width() < 661) {
            let documentWidth = $(document).width();
            documentWidth = documentWidth - 37;
        }
    }

    public handleEvent(event: any) {
        switch (event.name) {
            case 'assignWidth':
                this.setWidth();
                break;
        }
    }

    public subscribeForSearch() {
        this.subject.pipe(
            debounceTime(400)
        ).subscribe(search => {
            this.performGlobalSearch(search);
        });
    }

    public async performGlobalSearch(search: GlobalSearch) {
        this.searchResults = {};
        this.moduleResultNotFound = false;
        this.loadingStart();
        this.searching = true;
        this.searchResults = await this.globalSearchService.globalSearch(search);
        this.loadingFinished();
        this.checkIfNoRecords();
    }
    public navigateTo(route: string) {
        this.router.navigateByUrl('/portal/'+ route);
        this.hide();
    }
    public navigateToResult(result: SearchRecord) {
        this.router.navigateByUrl('/portal/' + result.link);
        this.hide();
    }

}
