import { Routes } from '@angular/router';
import { AuthenticationGuard } from './core';
import { LoginComponent } from './authentication/login';
import { FullComponent } from './shared';
import { LoggedInGuard } from './core/authentication/loggedIn.guard';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { LeaderBoardTraderoomComponent } from './leader-board-traderoom/leader-board-traderoom.component';

export const AppRoutes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full', canActivate: [AuthenticationGuard], },
    {
        path: 'under-maintenance',
        component: UnderMaintenanceComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'logout',
        component: LoginComponent
    },
    {
        path: 'leaderboard',
        component: LeaderBoardTraderoomComponent
    },
    {
        path: 'portal',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
        component: FullComponent,
    }
];
