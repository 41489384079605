import { Injectable, EventEmitter } from '@angular/core';
import { BaseAPIClass, AuthenticationService, LocalStorageService } from '@app/core';
import { HttpClient } from '@angular/common/http';
import { Setting } from '@app/shared/models/settings.model';
import { ShowEncryptDataPayload } from '../models/show-data.model';

@Injectable({providedIn: 'root'})
export class ShowEncryptService extends BaseAPIClass{
  public readonly URL_SHOW_ENCRYPT_DATA = 'show_encrypted_data';
  constructor(
    protected httpClient: HttpClient,
    protected authenticationService: AuthenticationService
    ) {
    super(httpClient, authenticationService);
  }

  public async showEncryptDataAsync(payload: ShowEncryptDataPayload) {
      return await this.postAsync<string>(this.URL_SHOW_ENCRYPT_DATA, payload, {}, false).toPromise();
  }
}
