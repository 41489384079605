import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ApplicationDataService } from '@app/core/service/application-data.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent extends AbstractBaseComponent implements OnInit {
  signupForm: FormGroup;
  isLoading = false;
  @ViewChild('autofocus') first_name: ElementRef;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private applicationDataService: ApplicationDataService,
    protected profileService: ProfileService) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.first_name.nativeElement.focus();
    }, 500);
  }
  public async signup() {
    this.httpProcessStart();
    this.isLoading = true;
    try {
      const registration  = await this.profileService.registerAsync(this.signupForm.value);
      this.router.navigate(['login'], {
          state: registration,
          queryParams: {
              signup: 'done'
      }});
      this.hasErrors = false;
    } catch (errorResponse) {
        this.errorsList = this.applicationDataService.getRequestErrors(errorResponse);
    }
    this.isLoading = false;
    this.httpProcessEnd();
  }

  private createForm() {
    this.signupForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
    });
  }
}
