<!-- <div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="detail">{{detail.colDef.headerName}}</h5>
        <button type="button" class="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Modal body text goes here.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
      </div>
    </div>
  </div>
</div> -->

<div class="modal-header">
  <h4 class="modal-title" *ngIf="detail">{{ detail.colDef.headerName }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-loading *ngIf="loading"></app-loading>
    <p *ngIf="!loading">{{data}}</p>
  </div>
<div class="modal-footer">
  <button type="button" class="btn shadow-0 btn-danger" (click)="close()">Close</button>
</div>
