<div class="container-fluid wrap">
    <div class="text-center time">
        <h4>{{time()}}</h4>
    </div>
    <div class="row">
        <div class="col-md-5">
            <div class="traderoom">
                <div class="text-center top-content">
                    <h3>Total Traderoom Today</h3>
                    <h1 class="total">{{todayTotal | currency: preferedCurrency}}</h1>
                    <div class="progress w-progress">
                        <div class="progress-bar" role="progressbar" min="0" max="100"
                            [ngStyle]="{'width': calcPercentages?.today+'%'}">
                            {{calcPercentages?.today}}%
                        </div>
                    </div>
                </div>
                <div class="row mt-3 mid-content">
                    <div class="col-6 text-center">
                        <h6 class="m-0">Monthly Deposits</h6>
                        <h1 class="fw-500 m-0">{{monthlyTotal | currency: preferedCurrency}}</h1>
                        <div class="progress w-progress">
                            <div class="progress-bar" role="progressbar" min="0" max="100"
                                [ngStyle]="{'width': calcPercentages?.month+'%'}">
                                {{calcPercentages?.month}}%
                            </div>
                        </div>
                    </div>
                    <div class="col-6 text-center">
                        <h6 class="m-0">Total Deposits</h6>
                        <h1 class="fw-500 m-0">{{total | currency: preferedCurrency}}</h1>
                        <div class="progress w-progress">
                            <div class="progress-bar" role="progressbar" min="0" max="100"
                                [ngStyle]="{'width': calcPercentages?.total+'%'}">
                                {{calcPercentages?.total}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="daily-winner">
                <div>
                    <img src="assets/images/award.png" alt="Flag" class="flag">
                    <span class="ml-1">Daily Monster</span>
                </div>
                <div class="d-flex align-items-center">
                    <img src="assets/images/currencies-flag/USD.png" alt="Flag" class="flag">
                    <div class="ml-1">
                        <span>{{leaderBoardList[0]?.first_name ? leaderBoardList[0]?.first_name +" "+leaderBoardList[0]?.last_name : '--'}}</span>
                        <p class="m-0">{{((dailyMonster?.total_deposit_amount_today - dailyMonster?.total_withdrawal_amount_today)  || 0) | currency: preferedCurrency}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="table-data table-responsive">
                <table class="table table-striped table-borderless table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Agent</th>
                            <th>Daily Net Deposits</th>
                            <th>Monthly Net Deposits</th>
                            <th>Total Net Deposits</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="!loading && leaderBoardList.length == 0">
                            <td colspan="5">
                                <app-no-records></app-no-records>
                            </td>
                        </tr>
                        <tr *ngIf="loading">
                            <td colspan="5">
                                <app-loading></app-loading>
                            </td>
                        </tr>
                        <tr *ngFor="let item of leaderBoardList;let i =index">
                            <td>{{1+i}}</td>
                            <td>{{item.first_name+" "+item.last_name}}</td>
                            <td>{{(item.total_deposit_amount_today - item.total_withdrawal_amount_today).toFixed(2) | currency: preferedCurrency}}
                            </td>
                            <td>{{(item.total_deposit_amount_this_month - item.total_withdrawal_amount_this_month).toFixed(2) | currency: preferedCurrency}}
                            </td>
                            <td>{{(item.total_deposit_amount - item.total_withdrawal_amount).toFixed(2) | currency: preferedCurrency}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
