<div class="modal-header shadow-sm">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h4 class="modal-title mt-2">Add New Lead</h4>
            </div>
            <div class="col text-right">
                <button class="btn btn-success waves-effect waves-themed shadow-none">
                    <span class="fal fa-print mr-1"></span> Button 1</button>
                <button class="btn btn-light waves-effect waves-themed ml-2 shadow-none">Button 2</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
            </div>
        </div>
    </div>
</div>
<div class="modal-body shadow-sm">
    <div class="container np-gutter">
        <div class="row">
            <div class="col-7">
                <div class="detailed-area">
                    123 
                </div>
            </div>
            <div class="col-5">
                <div class="module-loader">
                    123456
                </div>
            </div>
        </div>
    </div>
</div>