import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmailValidator, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorMessageService, AuthenticationService } from '@app/core';
import { AuthenticationEvent } from '@app/core/authentication/authentication.model';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { InternalServerError } from '@app/portal/settings/profile/profile.model';
import { ProfileService } from '@app/portal/settings/profile/profile.service';
import { UserPreferencesService } from '@app/portal/user-preferences/user-preferences.service';
import { NotificationBuilder } from '@app/shared/builders/NotificationBuilder';
import { ConfirmationDialogService } from '@app/shared/components/confirmation-dialog-component/confirmation-dialog.service';
import { Alert, AlertTypes } from '@app/shared/models/alert.model';
import { GenericEvent } from '@app/shared/models/generic-event.model';
import { NotificationTypeEnum, RealTimeNotificationEvent } from '@app/shared/models/notification.model';
import { User } from '@app/shared/models/user.model';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { DynamicScriptLoaderService } from '@app/shared/services/dynamic-script-loader.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

declare function require(moduleName: string): any;
const { version: appVersion } = require('../../../../../package.json')

declare const toastr: any;
declare const $: any;
@Component({
    selector: 'app-full-layout',
    templateUrl: './full.component.html',
    styleUrls: ['./full.component.scss'],
})
export class FullComponent extends AbstractBaseComponent implements OnInit, OnDestroy {
    public internalServerErrorForm: FormGroup;
    public appDataLoaded = false;
    public appVersion: string;
    public agent: User;
    public logId: string;
    public profile: User;
    protected bsModalRef: BsModalRef;
    private readonly SOUND_CLIP_CLAP = "../../../../assets/audio/clapping-sound.mp3";
    private readonly SOUND_CLIP_ALERT = "../../../../assets/audio/generic-alert.wav";
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        protected profileService: ProfileService,
        private applicationEvent: ApplicationEvent,
        protected confirmationDialogService: ConfirmationDialogService,
        private errorMessageService: ErrorMessageService,
        private applicationDataService: ApplicationDataService,
        private dynamicScriptLoaderService: DynamicScriptLoaderService,
        private userPreferencesService: UserPreferencesService,
        private authenticationService: AuthenticationService) {
        super();
        this.appVersion = appVersion;
        this.subscribe(this.applicationEvent.onAlert.subscribe(this.handleModalAlert.bind(this)));
        this.subscribe(this.authenticationService.onAuthenticationChange.subscribe(this.handleAuthenticationChange.bind(this)));
        this.subscribe(this.applicationEvent.onGenericEvent.subscribe(this.handleEvent.bind(this)));
        this.subscribe(this.errorMessageService.errors$.subscribe(this.handleHttpRequestError.bind(this)));
        this.subscribe(this.applicationEvent.onNotificationEvent.subscribe(this.handleNotification.bind(this)));
    }

    ngOnInit() {
        this.agent = this.profileService.getLocallySavedProfile();
        this.loadAppData();
        this.loadScripts();
        this.startPingWithIntervals();
        this.initForm();
    }

    private async loadAppData() {
        const response = await this.applicationDataService.getAppData();
        if (response != null) {
            this.applicationDataService.buildFieldOptionStore(response.app.field_options);
            this.applicationDataService.buildRBACLStore(response.app.assigned_rbacl);
            this.userPreferencesService.buildUserPreferencesStore(response.app.preferences);
            this.profile = await this.profileService.getProfileAsync(); // Preload
            this.appDataLoaded = true;
        } else {
            await this.authenticationService.logout().toPromise();
            this.router.navigateByUrl('login');
        }
    }

    private startPingWithIntervals() {
        setInterval(() => {
            this.profileService.ping();
        }, 60000);
    }

    private loadScripts() {
        this.dynamicScriptLoaderService.load(['datatables']).then((_) => {});
    }

    public switchAccount() {
        this.authenticationService.setTempCredentials();
        window.location.reload();
    }

    public handleModalAlert(alert: Alert) {
        this.alert = alert;
        ( < any > $('#alert-modal-md')).modal();
    }

    public handleAuthenticationChange(event: AuthenticationEvent) {
        switch (event.status) {
            case 'logged-out':
                this.router.navigateByUrl('login');
                break;
        }
    }

    private getToastrOptions() {
        return {
            'closeButton': true,
            'debug': false,
            'newestOnTop': true,
            'progressBar': true,
            'positionClass': 'toast-top-right',
            'preventDuplicates': true,
            'showDuration': 300,
            'hideDuration': 100,
            'timeOut': 5000,
            'extendedTimeOut': 1000,
            'showEasing': 'swing',
            'hideEasing': 'linear',
            'showMethod': 'fadeIn',
            'hideMethod': 'fadeOut'
        };
    }

    private handleHttpRequestError(errors: ErrorModel.ErrorMessageObject[]) {
        let html = '';
        for (const errorObject of errors) {
            html += errorObject.error + '<br>';
        }

        if (html !== '') {
            toastr.error(html, this.getToastrOptions());
        }
        if (errors[0]?.type=="Server") {
        this.logId = errors[0].log_id;
        }
    }

    private presentReportErrorModal() {
        this.confirmationDialogService.confirm('Oops..', 'Do you want to report for this error?')
            .then(async (confirmed: any) => {
                if (confirmed) {
                    this.bsModalRef = $('#internalServerError').modal('show');
                }
            });
    }

    private handleEvent(event: GenericEvent) {
        switch (event.name) {
            case 'internal-server':
                this.presentReportErrorModal();
                break;
            case 'close-modal':
                break;
            case 'close':
                break;
        }
    }
    public get alertTypes() {
        return AlertTypes;
    }
    public async save() {
        let internalServerError: InternalServerError = this.internalServerErrorForm.value;
        internalServerError.log_id = this.logId;
        const response: any = await this.profileService.saveInternalServerErrorInfo(internalServerError);
    }
    public closeModal() {
        this.applicationEvent.fireGenericEvent({
            name: 'close-modal'
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private playSound(type: string) {
        let audio = new Audio();
        switch (type) {
            case 'clap':
                audio.src = this.SOUND_CLIP_CLAP;
                break;
            case 'alert':
                audio.src = this.SOUND_CLIP_ALERT;
                break;
        }
        audio.load();
        audio.play();
    }

    private processNotificationForAudioAlert(notificationEvent: RealTimeNotificationEvent) {
        const notification: any = JSON.parse(notificationEvent.notification);
        const notificationType: NotificationTypeEnum = notification.type;
        switch (notificationType) {
            case NotificationTypeEnum.Deposit:
                this.playSound('clap');
                break;
            case NotificationTypeEnum.Withdrawal:
            case NotificationTypeEnum.Login:
                this.playSound('alert');
                break;
        }
    }

    private handleNotification(notificationEvent: RealTimeNotificationEvent) {
        if (notificationEvent.receiverIds.includes(this.profile.id)) {
            const notificationMessage = new NotificationBuilder(notificationEvent.notification).build();
            toastr.success(notificationMessage, this.getToastrOptions());
            this.processNotificationForAudioAlert(notificationEvent);
        }
    }
    private initForm() {
        this.internalServerErrorForm = this.formBuilder.group({
            email: ['', [EmailValidator]],
            description: ['', []],
            log_id: ['', []]
        });
    }
}
