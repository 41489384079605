<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="centered">
                <img src="../../assets/images/under-maintenance.png" class="img-fluid" />
                <div class="row mt-5">
                    <div class="col">
                        <h4 class="fw-500 text-center">We'll be right back</h4>
                        <p class="fw-400 text-center">We are performing some scheduled maintenance. <br>We expect to be back online shortly. Thank you for your patience.</p>

                        <div class="text-center">
                            <a href="/portal/dashboard">Home Page</a> | <a href="javascript:;" (click)="goBack()">Go Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
