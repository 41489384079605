import { Component } from '@angular/core';
import { ApplicationDataService } from '@app/core/service/application-data.service';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { IFloatingFilter } from 'ag-grid-community';

@Component({
  selector: 'app-client-status-filter',
  template: `<app-ag-set-filter (changed)="onParentModelChanged($event)" [name]="'assigned_to'" [filterTitle]="'Agent'" [options]="values"></app-ag-set-filter>`,
})

export class AssignedToFilterComponent implements IFloatingFilter, AgFrameworkComponent<any> {
    public params: any;
    public currentValue: number[] = [];
    public style: any;
    public values: any[] = [];

    constructor(private applicationDataService: ApplicationDataService) {

    }

    async agInit(params: any) {
        this.params = params;
        this.getValues();
    }

    onParentModelChanged(model: any) {
        this.currentValue = model;
        this.params.filterChangedCallback();
    }

    getModel() {
        return {
            filterType: 'array',
            type: 'contains',
            filter: this.currentValue,
            names: this.names
        };
    }

    isFilterActive() {
        return this.currentValue && this.currentValue.length > 0;
    }

    async getValues() {
        const users =  await this.applicationDataService.getUsers();
        users.forEach(user=> {
            this.values.push({id: user.id, name: user.first_name + " " + user.last_name});
        })
    }

    get names() {
        return this.currentValue.map((id: number) => {
            return this.values.find(user=> user.id == id).name;
        });
    }
}
