import { UserAccessGroupEnum } from './groups.model';
import { ModuleEnum } from './module.model';
import { SettingsSubMenu } from './settings-sidebar.model';
export enum NavItemType {
    Menu,
    NavTitle
}
export const BankingSubMenu = [
    {
        id: 0,
        title: 'Banks',
        link: '/portal/banks/list',
        icon: 'fal fa-university'
    },
    {
        id: 1,
        title: 'Bank Accounts',
        link: '/portal/bank-accounts/list',
        icon: 'fal fa-university'
    },
    {
        id: 2,
        title: 'Wire Transfers',
        link: '/portal/wire-transfers/list',
        icon: 'fa fa-university'
    }
];

export const NavigationMenu = [
  {
    id: 1100,
    title: 'Dashboard',
    link: '/portal/dashboard',
    icon: 'fal fa-chart-area',
    type: NavItemType.Menu
},
{
    id: ModuleEnum.Leads,
    title: 'Leaderboard',
    link: '/leaderboard',
    icon: 'fal fa-chess-board',
    type: NavItemType.Menu,
    periority: 'New'
  },
  {
    id: ModuleEnum.Leads,
    title: 'Leads',
    link: '/portal/leads',
    icon: 'fal fa-dot-circle',
    type: NavItemType.Menu,
    subMenu: [
        {
            id: ModuleEnum.Leads,
            title: 'Conversions',
            link: '/portal/leads/conversions',
            icon: 'fal fa-users',
            type: NavItemType.Menu
        },
        {
            id: ModuleEnum.Leads,
            title: 'Batch Duplication',
            link: '/portal/leads/batch-duplication',
            icon: 'fal fa-users',
            type: NavItemType.Menu
        }
    ]
  },
  {
    id: ModuleEnum.Accounts,
    title: 'Clients',
    link: 'javascript:;',
    icon: 'fal fa-shield-alt',
    type: NavItemType.Menu,
    subMenu: [
        {
            id: ModuleEnum.Documents,
            title: 'Clients',
            link: '/portal/clients',
            icon: 'fal fa-users',
            type: NavItemType.Menu
        },
        {
            id: ModuleEnum.Documents,
            title: 'Depositors',
            link: '/portal/clients/depositors',
            icon: 'fal fa-users',
            type: NavItemType.Menu
        },
        {
            id: ModuleEnum.Documents,
            title: 'Documents',
            link: '/portal/documents/list',
            icon: 'fal fa-file-alt',
            type: NavItemType.Menu
        },
    ]
  },
  {
    id: ModuleEnum.Marketing,
    title: 'Marketing',
    link: 'javascript:;',
    icon: 'fal fa-cube',
    type: NavItemType.Menu,
    subMenu:[
        {
            id:  ModuleEnum.Affiliates,
            title: 'Affiliates',
            link: '/portal/affiliates',
            icon: 'fal fa-user'
        },
        {
            id:  ModuleEnum.Campaign,
            title: 'Campaigns',
            link: '/portal/campaigns',
            icon: 'fal fa-user',
           type: NavItemType.Menu,
        },
        {
            id: ModuleEnum.Notifications,
            title: 'Notifications',
            link: '/portal/notifications/list',
            icon: 'fal fa-bell',
            type: NavItemType.Menu
        },
    ],
  },
  {
    id: ModuleEnum.TradingAccounts,
    title: 'Trading Accounts',
    link: '/portal/trading-platform-accounts/tp-list',
    icon: 'fal fa-trophy-alt',
    type: NavItemType.Menu
  },
  {
    id: ModuleEnum.MonetaryTransactions,
    title: 'Cashier',
    link: 'javascript:;',
    icon: 'fal fa-dollar-sign',
    type: NavItemType.Menu,
    subMenu: [
        {
            id:  ModuleEnum.MonetaryTransactions,
            title: 'Deposits',
            link: '/portal/cashier/transactions/deposits',
            icon: 'fal fa-dollar-sign',
           type: NavItemType.Menu,
        },
        {
            id:  ModuleEnum.MonetaryTransactions,
            title: 'Credits',
            link: '/portal/cashier/transactions/credits',
            icon: 'fal fa-dollar-sign',
           type: NavItemType.Menu,
        },
        {
            id:  ModuleEnum.MonetaryTransactions,
            title: 'Withdrawals',
            link: '/portal/cashier/transactions/withdrawals',
            icon: 'fal fa-dollar-sign',
           type: NavItemType.Menu,
        },
        {
            id:  ModuleEnum.MonetaryTransactions,
            title: 'Adjustments',
            link: '/portal/cashier/transactions/adjustments',
            icon: 'fal fa-dollar-sign',
           type: NavItemType.Menu,
        }
    ]
  },
  {
    id: ModuleEnum.Users,
    title: 'Agents',
    link: 'javascript:;',
    icon: 'fal fa-users',
    type: NavItemType.Menu,
    subMenu: [
        {
            title: 'Agents',
            link: '/portal/settings/users',
            icon: 'fal fa-building',
            type: NavItemType.Menu,
            id: ModuleEnum.Users
        },
        {
            title: 'Desks',
            link: '/portal/desks',
            icon: 'fal fa-building',
            type: NavItemType.Menu
        },
        {
            title: 'Departments',
            link: '/portal/departments',
            icon: 'fal fa-building',
            type: NavItemType.Menu
        },
        {
            title: 'Offices',
            link: '/portal/offices',
            icon: 'fal fa-building',
            type: NavItemType.Menu
        },
        {
            id: ModuleEnum.Profiles,
            title: 'Permissions',
            link: '/portal/settings/rbacl/profiles/create',
            icon: 'fal fa-cogs',
        },
        {
            id: ModuleEnum.Acl,
            title: 'Hierarchy',
            link: '/portal/settings/rbacl/roles/manage',
            icon: 'fal fa-cogs',
        },
    ]
  },
    {
        id: ModuleEnum.Calendar,
        title: 'Calendar',
        link: '/portal/calendar/view-calendar',
        icon: 'fal fa-calendar',
        type: NavItemType.Menu
    },

    {
        id: ModuleEnum.Emails,
        title: 'Emails',
        link: 'javascript:;',
        icon: 'fal fa-envelope',
        type: NavItemType.Menu,
        subMenu: [
            {
                id: ModuleEnum.Emails,
                title: 'Emails',
                link: '/portal/emails/lists',
                icon: 'fal fa-envelope',
            },
            {
                id: ModuleEnum.EmailTemplates,
                title: 'Email Templates',
                link: '/portal/settings/email-templates',
                icon: 'fal fa-email'
            },
        ]
    },
    {
        id: ModuleEnum.Tickets,
        title: 'Tickets',
        link: '/portal/tickets/list',
        icon: 'fal fa-ticket-alt',
        type: NavItemType.Menu
    },
    {
        id: 1100,
        title: 'Reports',
        link: '/portal/reports',
        icon: 'fal fa-code',
        type: NavItemType.Menu
    },
    {
        id: 1002,
        title: 'Settings',
        link: 'javascript:;',
        icon: 'fal fa-cog',
        type: NavItemType.Menu,
        subMenu: SettingsSubMenu,
        accessLevel: [UserAccessGroupEnum.Administrator]
    },
    // {
    //     id: 1001,
    //     title: 'Beta Modules',
    //     link: 'javascript:;',
    //     icon: 'fal fa-file',
    //     type: NavItemType.Menu,
    //     isDBOnlyModule: true, // Available to DB users only
    //     subMenu: [
    //         {
    //             id: ModuleEnum.EmsEmployee,
    //             title: 'Employees',
    //             link: '/portal/employees',
    //             icon: 'fal fa-user-alt',
    //             type: NavItemType.Menu
    //         },
    //         {
    //             id: ModuleEnum.EmsEmployee,
    //             title: 'Payslips',
    //             link: '/portal/payslips',
    //             icon: 'fal fa-file',
    //             type: NavItemType.Menu
    //         },
    //         {
    //             id: ModuleEnum.EmsEmployee,
    //             title: 'Employee Attendance',
    //             link: '/portal/employee-attendance',
    //             icon: 'fa-solid fa-calendar-circle-user',
    //             type: NavItemType.Menu
    //         }
    //     ],
    //     accessLevel: [UserAccessGroupEnum.Administrator]
    // },
    // {
    //     id: 1001,
    //     title: '**',
    //     link: 'javascript:;',
    //     icon: 'fal fa-file',
    //     type: NavItemType.Menu,
    //     isDBOnlyModule: true, // Available to DB users only
    //     subMenu: [
    //         {
    //             id: 21,
    //             title: 'Clients',
    //             link: '/portal/ems/clients/list',
    //             icon: 'fal fa-users',
    //           },
    //         {
    //             id: 25,
    //             title: 'Expenses',
    //             link: '/portal/ems/expenses',
    //             icon: 'fal fa-calculator',
    //             type: NavItemType.Menu
    //         },

    //         {
    //             id: 29,
    //             title: 'Credit Notes',
    //             link: '/portal/vendor/invoices/credit-note',
    //             icon: 'fal fa-sticky-note',
    //             type: NavItemType.Menu,
    //       },
    //       {
    //         id: 23,
    //         title: 'Invoices',
    //         link: '/portal/vendor/invoices/list-invoices',
    //         icon: 'fal fa-file-alt',
    //         type: NavItemType.Menu,
    //       },
    //       {
    //         id: 24,
    //         title: 'Fleet Management',
    //         link: '/portal/vendor/fleet',
    //         icon: 'fal fa-car',
    //         type: NavItemType.Menu,
    //         subMenu: [
    //             {
    //                 id: 29,
    //                 title: 'Refunds',
    //                 link: '/portal/vendor/fleet-refund',
    //                 icon: 'fal fa-cogs',
    //             }]
    //         },
    //         {
    //             id: 24,
    //             title: 'Inventory',
    //             link: '/portal/vendor/dashboard',
    //             icon: 'fal fa-box',
    //             type: NavItemType.Menu
    //         },
    //         {
    //             id: 24,
    //             title: 'Fleet Stock',
    //             link: '/portal/vendor/fleet-stock',
    //             icon: 'fal fa-truck',
    //             type: NavItemType.Menu
    //           },{
    //             id: 24,
    //             title: 'Stock Intake',
    //             link: '/portal/vendor/stock-intake',
    //             icon: 'fal fa-barcode',
    //             type: NavItemType.Menu,
    //         }
    //     ],
    //     accessLevel: [UserAccessGroupEnum.Administrator]
    // }
];
