import { Component } from '@angular/core';
import { ChangeTradingAccountPasswordComponent } from '@app/portal/trading-accounts/change-trading-account-password/change-trading-account-password.component';
import { AgRendererComponent } from 'ag-grid-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationEvent } from '../services/alert-modal.service';
import { GenericEvent } from '../models/generic-event.model';


@Component({
    template: `<div class="d-flex align-items-center wrapper">
                    <button class="btn-outline-info d-flex justify-content-center align-items-center custom-button" (click)="changeTradingAccountPassword()">
                        <i class="fal fa-lock-alt icon"></i>
                    </button>
                </div>`,
    styles: ['.wrapper{height: 100%;} .custom-button{width: 25px; border-radius: 50px; height: 25px; border: 1px solid; background: none !important;} .custom-button:hover{background-color: #17a2b8 !important;} .icon{font-size: 12px !important;}']
})
export class ActionButtonRendererComponent implements AgRendererComponent {
    public params: any;
    public link: string;
    public isOnline: boolean;
    public bsModalRef: BsModalRef
    constructor(private bsModalService: BsModalService) { }

    agInit(params: any): void {
        this.params = params;
    }

    changeTradingAccountPassword() {
        this.bsModalRef = this.bsModalService.show(ChangeTradingAccountPasswordComponent, { ignoreBackdropClick: true});
        this.bsModalRef.setClass('modal-md');
        this.bsModalRef.content.setDetail(this.params);
        this.bsModalRef.content.emitData.subscribe( (event: GenericEvent) => {
            if (event.name == 'close') {
                this.bsModalRef.hide();
            }
        });
    }

    refresh(params: any): boolean {
        return false;
    }



}
