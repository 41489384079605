import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CommentsFilter, CommentsResponse, Comment, MassComment, CommentResponse } from '@app/shared/models/comment.model';

@Injectable({ providedIn: 'root' })
export class CommentsService extends BaseAPIClass {

  private readonly URL_FETCH_COMMENTS: string = 'comments';
  private readonly URL_CREATE_COMMENT: string = 'comments';
  private readonly URL_DELETE_COMMENT: string = 'comments';
  private readonly URL_UPDATE_COMMENT: string = 'comments/';
  private readonly URL_STAR_COMMENT: string = 'star-comment';
  private readonly URL_UNSTAR_COMMENT: string = 'unstar-comment';

  constructor(
    protected httpClient: HttpClient,
    protected authenticationService: AuthenticationService) {
    super(httpClient, authenticationService);
  }

  public async fetchCommentsAsync(commentsFilter: CommentsFilter) {
    return await this.getAsync<CommentsResponse>(this.URL_FETCH_COMMENTS, commentsFilter, {}, false).toPromise();
  }

  public async createComment(comment: MassComment) {
    return await this.postAsync<CommentResponse>(this.URL_CREATE_COMMENT, comment, {}, false).toPromise();
  }

  public async updateCommentAsync(payload: Comment) {
    return await this.putAsync<CommentResponse>(this.URL_UPDATE_COMMENT + payload.id.toString(), payload, {}, false).toPromise();
  }

  public async deleteCommentAsync(commentId: number) {
    return await this.deleteAsync<CommentResponse>(this.URL_DELETE_COMMENT + '/' + commentId, {}, {}, false).toPromise();
  }

  public async starCommentAsync(id: number) {
    return await this.postAsync<CommentResponse>(this.URL_STAR_COMMENT, { id }, {}, false).toPromise();
  }

  public async unstarCommentAsync(id: number) {
    return await this.postAsync<CommentResponse>(this.URL_UNSTAR_COMMENT, { id }, {}, false).toPromise();
  }


}
