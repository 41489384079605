import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LeadsService } from '@app/portal/leads/leads.service';
import { ListedLeadsSource, ListedLeadsSourceResponse } from '@app/portal/leads/models/lead.model';
import { FieldOption } from '@app/portal/settings/field-options/model/field-option.model';

@Component({
  selector: 'app-get-sumo-filter-by-array',
  templateUrl: './get-sumo-filter-by-array.component.html',
  styleUrls: ['./get-sumo-filter-by-array.component.css']
})
export class GetSumoFilterByArrayComponent implements OnInit {
  @Input() public typeId: number;
  @Input() public valueField = 'id';
  @Input() public name: string;
  @Input() public control: FormControl;
  @Input() public group: FormGroup;
  @Output() changed = new EventEmitter();
  @Input() public module: number|null = null;
  public selectedValues: number[] = [];
  public options: string[] = [];
  public sumoSelectOptions: any = {};
  public ready:boolean = false;
  public loading: boolean = true;

  constructor(
      private leadsService: LeadsService
  ) { }
  ngOnInit() {
      this.getCountries();
  }
  addClassOverFlow(event: any){
      if($(event.target).hasClass('overFlowDiv')){
          $(event.target).removeClass('overFlowDiv');
      }
  }
  removeClassOverFlow(event: any){
      if(!$(event.target).hasClass('overFlowDiv')){
          $(event.target).addClass('overFlowDiv');
      }

  }

  public async getCountries() {
      this.loading = true;
      const options: string[] = [];
      if(this.module = 30) {
          const response: ListedLeadsSourceResponse = await this.leadsService.fetchListedLeadsSourceAsync();
              response.lead_sources.forEach((option: ListedLeadsSource) => {
                if(option.lead_source != null) {
                  options.push(option.lead_source);
                }
              });

      }
      this.options = options;
      this.loading = false;
  }

  public getValue(option: FieldOption) {
    return option;
      // return (this.valueField === 'name') ? option.name : option.id;
  }

  public valueChanged($event: any) {
      const value = $event.currentTarget.value;
      if ($event.currentTarget.checked) {
          this.selectedValues.push(value);
      } else {
          this.selectedValues = this.selectedValues.filter(v => v != value);
      }
  }

  public apply() {
      this.changed.emit(this.selectedValues);
  }

  public clear() {
      $('.set-checkbox').prop('checked', false)
      this.selectedValues = [];
      this.changed.emit(this.selectedValues);
  }

  public filterList(event: any) {
      let filter, cardRow, data, i, txtValue;
      filter = event.target.value.toUpperCase();
      cardRow = document.getElementsByClassName('filterdata') as HTMLCollectionOf<HTMLElement>;
      const cards = document.getElementsByClassName('AllRows') as HTMLCollectionOf<HTMLElement>;
      for (i = 0; i < cardRow.length; i++) {
          data = $(cardRow[i]).html();
          if (data) {
              txtValue = data;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                  cards[i].style.display = '';
              } else {
                  cards[i].style.display = 'none';
              }
          }
      }
  }

}
