import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPIClass, AuthenticationService } from '@app/core';
import { TraderoomResponse } from './model/traderoom.model';
@Injectable({
    providedIn: 'root'
})
export class TraderoomService extends BaseAPIClass {
    private readonly URL_FETCH_TRADERS: string = 'reports/leader_board/traders-room';
    private readonly URL_FETCH_BRAND_TARGETS: string = 'brand-targets/{id}';

    constructor(
        protected httpClient: HttpClient,
        protected authenticationService: AuthenticationService
    ) {
        super(httpClient, authenticationService);
    }

    public async fetchTraderoomAsync() {
        return await this.getAsync<TraderoomResponse[]>(this.URL_FETCH_TRADERS, {}, {}, false).toPromise();
    }

    public async fetchBrandTargetsAsync(brandId: number) {
        const url = this.URL_FETCH_BRAND_TARGETS.replace('{id}', brandId.toString());
        return await this.getAsync<{ targets: string }>(url, {}, {}, false).toPromise();
    }

}
