import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AbstractBaseComponent } from '@app/core/class/abstract.base.omponent';
import { ApplicationEvent } from '@app/shared/services/alert-modal.service';
import { CommentResponse, MassComment } from '@app/shared/models/comment.model';
import { CommentsService } from '../comments.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from '@app/shared/services/toastr.service';

@Component({
    selector: 'app-add-comment',
    templateUrl: './add-comment.component.html',
    styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent extends AbstractBaseComponent implements OnInit {
    public commentForm: FormGroup;
    @Output() emitData = new EventEmitter();
    @Input() public module: string;
    @Input() public relation_id: number;
    @Input() public relation_ids: number[] = [];
    @Input() public mass_comment: boolean;
    private bsModalRef: BsModalRef;
    @Input() public is_reply = 'No';
    public shortForm = true;
    public showInModal = false;
    public isdisable:boolean = false;

    constructor(private applicationEvent: ApplicationEvent,
        private commentsService: CommentsService,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder) {
        super();
    }

    ngOnInit() {
        this.pageIcon = 'fal fa-comments';
        this.pageTitle = 'New Comment';
        this.initForm();

    }

    public setMassComment(massComment: boolean) {
        this.mass_comment = massComment;
    }

    public setRelationIds(relationIds: number[]) {
        this.relation_ids = relationIds;
    }
    public closeModel() {
     this.applicationEvent.fireGenericEvent({ name: 'close-modal' });
    }

    public setModule(module: string) {
        this.module = module;
    }

    public setIsReply(isReply: boolean) {
        this.is_reply = (isReply) ? 'Yes' : 'No';
    }

    public async save() {
        try {
            this.httpProcessStart();
            console.log('comment');
            const massComment: MassComment = this.commentForm.value;
            massComment.module = this.module;
            massComment.is_reply = this.is_reply;
            if (this.relation_ids.length > 0) {
                massComment.relation_ids = this.relation_ids;
            } else {
                massComment.relation_ids = [this.relation_id];
            }
            console.log(massComment);
            const response: CommentResponse = await this.commentsService.createComment(massComment);
            this.applicationEvent.fireGenericEvent({ name: 'comment-created', payload: response.comment });
            this.commentForm.reset();
            this.isdisable = true;
        } catch (error) {
            if (error.constructor.name !== 'HttpErrorResponse') {
                this.toastrService.toastError(error);
            }
        }
        this.httpProcessEnd();
    }
    private initForm() {
        this.commentForm = this.formBuilder.group({
            comment: ['', [Validators.required]]
        });
        this.ready = true;
    }
}
