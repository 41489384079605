import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { LeadsResponse, LeadResponse, LeadFilter, TransferOwnership, LeadViewers, LeadViewerResponse, LeadWatchers, AssignLeadsPayload, AssignLeadsResponse, ConvertToAccountPayload, LeadsCountriesResponse, ListedLeadsStatusResponse, ListedLeadsSourceResponse, ListedLeadsAssignessResponse, BatchDuplicationResponse, LeadOwnershipActivityPaginate } from './models/lead.model';
import { ILeadCommentReqeust, Lead } from '@app/shared/models/lead.model';


@Injectable({
    providedIn: 'root'
})

export class LeadsService extends BaseAPIClass {
    private readonly URL_FETCH_LEADS_CONVERSION: string = 'leads/conversion-grid';
    private readonly URL_FETCH_LEADS: string = 'leads/list-grid';
    private readonly URL_FETCH_LEAD: string = 'leads/{id}';
    private readonly URL_GET_LEAD_WITH_FILTER_MODEL: string = 'get_lead/{id}';
    private readonly URL_CREATE_LEADS: string = 'leads';
    private readonly URL_IMPORT_LEADS: string = 'leads/import/file_headers';
    private readonly URL_EXPORT_LEADS: string = 'leads/export';
    private readonly URL_TRANSFER_OWNERSHIP: string = 'lead/transfer_ownership';
    private readonly URL_LEAD_VIEWERS: string = 'add_lead_viewers';
    private readonly URL_FETCH_LEADS_VIEWER: string = 'lead_viewers_listing';
    private readonly URL_DELETE_LEADS_VIEWER: string = 'remove_lead_viewer/{id}';
    private readonly URL_MASS_DELETE_LEADS_VIEWER: string = 'mass_delete_lead_viewers';
    private readonly URL_FIND_DUPLICATE: string = 'lead/find_duplicates';
    private readonly URL_UPDATE_LEADS: string = 'lead/update';
    private readonly URL_LEADS_COMMENT: string = 'lead/comment';
    private readonly URL_LEADS_COMMENT_UPDATE: string = 'lead/comment/update';
    private readonly URL_LEADS_COMMENT_DELETE: string = 'lead/comment/delete';
    private readonly URL_LEAD_MASS_DELETE: string = 'lead/mass_delete';
    private readonly URL_LEAD_MASS_UPDATE: string = 'lead/mass_update';
    private readonly URL_DELETE_LEAD: string = 'leads/';
    private readonly URL_CREATE_FAVOIRTE_LEADS: string = 'leads/add-to-favorites';
    private readonly URL_REMOVE_FAVOIRTE_LEADS: string = 'leads/remove-from-favorites';
    private readonly URL_FETCH_FAVOIRTE_LEADS: string = 'leads/list-favorites';
    private readonly URL_LEAD_WATCHERS: string = 'add_lead_watchers';
    private readonly URL_FETCH_LEADS_WATCHERS: string = 'lead_watchers_listing';
    private readonly URL_MAKE_LEADS_CALL: string = 'leads/call';
    private readonly URL_DISTRIBUTE_LEADS: string = 'lead/distribute';
    private readonly URL_CONVERT_TO_ACCOUNT: string = 'lead/convert';
    private readonly URL_GET_LEAD_COMMENTS_BY_ID: string = 'lead/{id}/comments';
    private readonly URL_GET_COUNTRIES_LEADS: string = 'leads/lead_countries';
    private readonly URL_GET_LISTED_LEADS_STATUS: string = 'leads/leads_listed_status';
    private readonly URL_GET_LISTED_LEADS_SOURCES: string = 'leads/lead_sources';
    private readonly URL_GET_LISTED_LEADS_ASSIGNESS: string = 'leads/leads_listed_status';
    private readonly URL_FETCH_BATCH_DUPLICATION: string = 'lead/batch-duplication';
    private readonly URL_DELETE_BATCH_DUPLICATION: string = 'lead/delete-batch-duplication';
    private readonly URL_GET_LEAD_OWNERSHIP_LOG:string="leads/get-lead-ownership-log";

    private leads: any[] = [];


    constructor(
        protected httpClient: HttpClient,
        protected authenticationService: AuthenticationService) {
        super(httpClient, authenticationService);
    }


    public setLeads(leads: String[]) {
        this.leads = leads;

    }
    public getleads() {
        return this.leads;
    }


    public async fetchLeadsConversions(leadFilter: LeadFilter) {
        return await this.postAsync<any>(this.URL_FETCH_LEADS_CONVERSION, leadFilter, {}, false).toPromise();
    }

    public async fetchLeads(leadFilter: LeadFilter) {
        return await this.postAsync<LeadsResponse>(this.URL_FETCH_LEADS, leadFilter, {}, false).toPromise();
    }

    public async fetchSingleLead(id: number, filterModel: string) {
        const url: string = this.URL_GET_LEAD_WITH_FILTER_MODEL.replace('{id}', id.toString());
        return await this.postAsync<LeadResponse>(url, {filterModel}, {}, false).toPromise();
    }

    public async fetchLead(filter: any = {}) {
        return await this.getAsync<LeadsResponse>(this.URL_CREATE_LEADS, filter, {}, false).toPromise();
    }

    public async fetchLeadById(id: number) {
        return await this.getAsync(this.URL_FETCH_LEADS + '/' + id, {}, {}, false).toPromise();
    }

    public async saveLeadAsync(lead: Lead) {
        return await this.postAsync<LeadResponse>(this.URL_CREATE_LEADS, lead, {}, false).toPromise();
    }

    public async updateLeadAsync(lead: Lead) {
        return await this.postAsync<LeadResponse>(this.URL_UPDATE_LEADS, lead, {}, false).toPromise();
    }

    public async leadsCommentAsync(lead: Lead) {
        return await this.postAsync<LeadResponse>(this.URL_LEADS_COMMENT, lead, {}).toPromise();
    }

    public async leadsCommentUpdateAsync(lead: ILeadCommentReqeust) {
        return await this.putAsync<LeadResponse>(this.URL_LEADS_COMMENT_UPDATE, lead, {}).toPromise();
    }


    public async leadsCommentDeleteAsync(lead: Lead) {
        return await this.postAsync<LeadResponse>(this.URL_LEADS_COMMENT_DELETE, lead, {}).toPromise();
    }

    public async deleteLeadAsync(lead: Lead) {
        console.log('call');
        return await this.deleteAsync<LeadResponse>(this.URL_DELETE_LEAD + lead.id, lead, {}, false).toPromise();
    }

    public async massDelete(massIds: number[]) {
        return await this.postAsync<LeadResponse>(this.URL_LEAD_MASS_DELETE, { lead_id: massIds }, {}).toPromise();
    }
    public async massUpdate(lead: Lead) {
        return await this.postAsync<LeadResponse>(this.URL_LEAD_MASS_UPDATE, lead, {}).toPromise();
    }
    public async saveTransferOwnershipAsync(lead: TransferOwnership) {
        return await this.postAsync<LeadResponse>(this.URL_TRANSFER_OWNERSHIP, lead, {}, false).toPromise();
    }
    //To add Lead Veiwer
    public async saveLeadViewersAsync(lead: LeadViewers) {
        return await this.postAsync<LeadResponse>(this.URL_LEAD_VIEWERS, lead, {}, false).toPromise();
    }
    public async fetchLeadViewers() {
        return await this.getAsync<LeadsResponse>(this.URL_FETCH_LEADS_VIEWER, {}, {}, false).toPromise();
    }
    public async saveLeadWatchersAsync(leadWatchers: any) {
        return await this.postAsync<LeadResponse>(this.URL_LEAD_WATCHERS, leadWatchers, {}, false).toPromise();
    }
    public async fetchLeadWatchersForId(payload: any) {
        return await this.postAsync<LeadWatchers>(this.URL_FETCH_LEADS_WATCHERS, payload, {}, false).toPromise();
    }

    public async deleteLeadViewerAsync(leadViewers: LeadViewers) {
        // return await this.deleteAsync<LeadResponse>(this.URL_DELETE_LEADS_VIEWER + leadViewers.id, leadViewers, {}, false).toPromise();
        const url = this.URL_DELETE_LEADS_VIEWER.replace('{id}', leadViewers.id.toString());
        return await this.deleteAsync<LeadViewerResponse>(url, leadViewers, {}, false).toPromise();
    }
    public async massDeleteViewers(massIds: number[]) {
        return await this.postAsync<LeadResponse>(this.URL_MASS_DELETE_LEADS_VIEWER, { lead_viewer_ids: massIds }, {}).toPromise();
    }

    public async saveImportLeadAsync(lead: Lead) {
        return await this.postAsync<LeadResponse>(this.URL_IMPORT_LEADS, lead, {}, false).toPromise();
    }

    public async saveExportLeadAsync(lead: Lead) {
        return await this.postAsync<LeadResponse>(this.URL_EXPORT_LEADS, lead, {}, false).toPromise();
    }
    public async saveFindDuplicateAsync(lead: Lead) {
        return await this.postAsync<LeadResponse>(this.URL_FIND_DUPLICATE, lead, {}, false).toPromise();
    }
    public async saveFavoriteLeadsAsync(leadIds: {}) {
        return await this.postAsync<LeadResponse>(this.URL_CREATE_FAVOIRTE_LEADS, leadIds, {}, false).toPromise();
    }
    public async removeFavoriteLeadsAsync(leadIds: {}) {
        return await this.postAsync<LeadResponse>(this.URL_REMOVE_FAVOIRTE_LEADS, leadIds, {}, false).toPromise();
    }
    public async fetchFavoriteLeadsAsync() {
        return await this.postAsync<LeadResponse>(this.URL_FETCH_FAVOIRTE_LEADS, {}, {}, false).toPromise();
    }

    public async makeLeadsCall(payload: any) {
        return await this.postAsync(this.URL_MAKE_LEADS_CALL, payload, {}, false).toPromise();
    }

    public async distributeLeads(payload: AssignLeadsPayload) {
        return await this.postAsync<AssignLeadsResponse>(this.URL_DISTRIBUTE_LEADS, payload, {}, false).toPromise();
    }

    public async convertToAccount(payload: ConvertToAccountPayload) {
        return await this.postAsync<AssignLeadsResponse>(this.URL_CONVERT_TO_ACCOUNT, payload, {}, false).toPromise();
    }
    public async fetchLeadCommentsById(leadId: number) {
        const url = this.URL_GET_LEAD_COMMENTS_BY_ID.replace('{id}', leadId.toString());
        return await this.getAsync<LeadsResponse>(url, {}, {}, false).toPromise();
    }

    public async fetchCountriesForLeads() {
        return await this.getAsync<LeadsCountriesResponse>(this.URL_GET_COUNTRIES_LEADS, {}, {}, false).toPromise();
    }

    public async fetchListedLeadStatusAsync() {
        return await this.getAsync<ListedLeadsStatusResponse>(this.URL_GET_LISTED_LEADS_STATUS, {}, {}, false).toPromise();
    }

    public async fetchListedLeadsSourceAsync() {
        return await this.getAsync<ListedLeadsSourceResponse>(this.URL_GET_LISTED_LEADS_SOURCES, {}, {}, false).toPromise();
    }

    public async fetchListedLeadsAssignee() {
        return await this.getAsync<ListedLeadsAssignessResponse>(this.URL_GET_LISTED_LEADS_ASSIGNESS, {}, {}, false).toPromise();
    }

    public async fetchBatchDuplicationAsync(filter:any)
    {
        return await this.getAsync<BatchDuplicationResponse>(this.URL_FETCH_BATCH_DUPLICATION, filter, {}, false).toPromise();
    }

    public async deleteDuplicateBatchLeadAsync(filter:any)
    {
        return await this.getAsync<AssignLeadsResponse>(this.URL_DELETE_BATCH_DUPLICATION, filter, {}, false).toPromise();
    }

    public async fetchLeadOwnershipLog(filter: any = {}) {
        return await this.getAsync<LeadOwnershipActivityPaginate>(this.URL_GET_LEAD_OWNERSHIP_LOG, filter, {}, false).toPromise();
    }
}
