
export interface RealTimeNotificationEvent {
    receiverIds: number[];
    notification: string;
}

export interface Notification {
    type: number;
    message: string;
    data: any;
}

export interface NotificationFilter {
    page?: number;
    per_page?: number;
}

export interface CreateNotification {
    id?: number;
    receiver_id?: number;
    type?: number;
    title?: string;
    body?: string;
    payload?: any;
    delivery_status?: string;
    start_date?: string;
    end_date?: string;
}
export interface Notifications {
    id: number;
    type: number;
    title: string;
    body: string;
    payload: string;
    delivery_status: string;
    start_date: string;
    end_date: string;
}

export interface NotificationPaginate {
    data: Notifications[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url?: any;
    path: string;
    per_page: number;
    prev_page_url?: any;
    to: number;
    total: number;
}

export interface NotificationResponse {
    notifications: NotificationPaginate;
}

export enum NotificationTypeEnum {
    Login        = 698,
    Deposit      = 699,
    Withdrawal   = 700,
}


export enum NotificationMediumEnum {
    Email                 = 672,
    PushNotification      = 673,
    Internal              = 674
}
