<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="w-100 warning mt-2">
        <img src="/assets/images/warning.jpg" width="60" alt="">
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
<div class="modal-footer">
    <button type="button" class="btn shadow-0 btn-danger" (click)="accept()">{{ btn_ok_text }}</button>
    <button type="button" class="btn shadow-0 btn-light" (click)="decline()">{{ btn_cancel_text }}</button>
</div>
